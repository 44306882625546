import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ABOUT_BG, MISSION_IMG, VISION_IMG } from "../utils/constant";
import { Link } from "react-router-dom";
import OurLeadership from "../components/OurLeadership";

function AboutUs() {
	const location = useLocation();
	const { pathname } = location;

	useEffect(() => {
		if (pathname === "/about-us") {
			document.title = "About - Sumcon Infraventures";
		}
	}, [pathname]);

	const SectionCard = ({ title, items, Card_BG }) => (
		<div className="relative p-5 rounded-md overflow-hidden w-full max-w-3xl lg:max-w-md xl:max-w-xl">
			{/* Background Image with Overlay */}
			<div className="absolute inset-0">
				<img
					src={Card_BG}
					alt={title}
					className="w-full h-full object-cover"
				/>
				<div className="absolute inset-0 bg-black opacity-50"></div>
			</div>

			{/* Content */}
			<div className="relative z-10 p-5">
				<h4 className="text-xl font-bold text-white mb-3">{title}</h4>
				<ul className="space-y-3">
					{items.map((item, index) => (
						<li key={index} className="flex items-start gap-3">
							{item.icon}
							<span className="text-[#ccd0d5]">{item.text}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);

	const visionItems = [
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					stroke="#FFD700"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 1 1 0 6h-4a3 3 0 0 1 -2.7 -2" />
					<path d="M12 3v3m0 12v3" />
				</svg>
			),
			text: (
				<>
					<b>Profit for Shareholders:</b> Ensuring sustainable growth
					and profitability.
				</>
			),
		},
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke="#FFD700"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="icon icon-tabler icons-tabler-outline icon-tabler-stars"
				>
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<path d="M17.8 19.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
					<path d="M6.2 19.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
					<path d="M12 9.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
				</svg>
			),
			text: (
				<>
					<b>Customer Satisfaction:</b> Delivering projects with
					reliability and quality.
				</>
			),
		},
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					stroke="#FFD700"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" />
					<path d="M10 10c-.5 -1 -2.5 -1 -3 0" />
					<path d="M17 10c-.5 -1 -2.5 -1 -3 0" />
					<path d="M14.5 15a3.5 3.5 0 0 1 -5 0" />
				</svg>
			),
			text: (
				<>
					<b>Enthusiasm Without Ego:</b> Fostering humility and
					passion.
				</>
			),
		},
	];

	const missionItems = [
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke="#FFD700"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="icon icon-tabler icons-tabler-outline icon-tabler-buildings"
				>
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<path d="M4 21v-15c0 -1 1 -2 2 -2h5c1 0 2 1 2 2v15" />
					<path d="M16 8h2c1 0 2 1 2 2v11" />
					<path d="M3 21h18" />
					<path d="M10 12v0" />
					<path d="M10 16v0" />
					<path d="M10 8v0" />
					<path d="M7 12v0" />
					<path d="M7 16v0" />
					<path d="M7 8v0" />
					<path d="M17 12v0" />
					<path d="M17 16v0" />
				</svg>
			),
			text: (
				<>
					<b>Excelling in Infrastructure:</b> Leadership in railways,
					roads, and urban development.
				</>
			),
		},
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke="#FFD700"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="icon icon-tabler icons-tabler-outline icon-tabler-clipboard"
				>
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
					<path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
				</svg>
			),
			text: (
				<>
					<b>Vocational Training:</b> Contributing to free skill
					development for the unemployed.
				</>
			),
		},
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					stroke="#FFD700"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="icon icon-tabler icons-tabler-outline icon-tabler-chart-bar"
				>
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<path d="M3 13a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
					<path d="M15 9a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
					<path d="M9 5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
					<path d="M4 20h14" />
				</svg>
			),
			text: (
				<>
					<b>Rising Over Competitors:</b> Staying ahead through
					innovation and quality.
				</>
			),
		},
	];

	return (
		<>
			<section className="w-full px-5 py-16 bg-[#F5F5F5]">
				<div className="container mx-auto">
					<div className="flex flex-col items-center md:flex-row gap-12">
						<div className="w-full md:w-1/2">
							<img
								src={ABOUT_BG}
								alt="About Us"
								className="w-full h-auto object-cover"
							/>
						</div>
						<div className="w-full md:w-1/2 text-center md:text-left">
							<h1 className="text-3xl lg:text-5xl font-semibold text-[#4682B4] mb-4">
								About Us
							</h1>
							<p className="text-[#333333] text-base font-sans leading-7 mb-4">
								We are a trusted name in the manufacturing and
								supply of railway track materials and have
								expanded our expertise to deliver end-to-end
								railway infrastructure solutions. Our services
								include linking and laying railway tracks,
								constructing embankments, roads, bridges, and
								other infrastructure, including Overhead
								Electrification (OHE) and signaling works.
							</p>
							<p className="text-[#333333] text-base font-sans leading-7 mb-4">
								Accredited with ISO 9001:2015 certification, we
								specialize in the design and execution of
								railway construction, rehabilitation, and
								engineering projects. Our portfolio includes
								collaborations with renowned PSUs such as RITES
								Ltd., Indian Oil Corporation, NTPC Ltd., and
								more.
							</p>
							<p className="text-[#333333] text-base font-sans leading-7 mb-4">
								We have also executed private railway siding
								projects for leading corporations like Vedanta
								Ltd., Ultratech Cement, JSW Cement, Tata Steel,
								and many others. With a proven track record, we
								are committed to delivering innovative,
								reliable, and high-quality solutions for all
								your railway infrastructure needs.
							</p>

							{pathname !== "/about-us" && (
								<Link
									to="/about-us"
									className="inline-block px-8 py-3 border border-[#333333] text-[#333333] rounded-md hover:bg-[#4682B4] hover:text-white hover:border-none"
								>
									Learn More
								</Link>
							)}
						</div>
					</div>
					<div className="mt-16">
						<h2 className="text-center text-4xl font-semibold text-[#4682B4] mb-8">
							Our Vision & Mission
						</h2>
						<div className="flex flex-wrap justify-center gap-6">
							<SectionCard
								title="Our Vision"
								items={visionItems}
								Card_BG={MISSION_IMG}
							/>
							<SectionCard
								title="Our Mission"
								items={missionItems}
								Card_BG={VISION_IMG}
							/>
						</div>
					</div>
				</div>
			</section>
			<OurLeadership />
		</>
	);
}

export default AboutUs;
