import ANUJ from "../assets/leaders/gray-user-profile-icon-png-fP8Q1P.png";
import GUNJAN from "../assets/leaders/gray-user-profile-icon-png-fP8Q1P.png";
import RAVINDRA from "../assets/leaders/gray-user-profile-icon-png-fP8Q1P.png";

export const leaders = [
	{
		img: RAVINDRA,
		name: "Ravindra Singhania",
		role: "Founder & Chairman, Sumcon Infraventures",
	},
	{
		img: GUNJAN,
		name: "Gunjan Singhania",
		role: "CEO, Sumcon Infraventures",
	},
	{
		img: ANUJ,
		name: "Anuj Singhania",
		role: "Managing Director, Sumcon Infraventures",
	},
];
