import JobDetails from "../components/JobDetails";
import ApplicationForm from "../components/ApplicationForm";

export default function ApplyPage() {
	return (
		<div className="container mx-auto flex justify-center px-4 py-8">
			<div className="border rounded-lg p-6 shadow-md max-w-2xl space-y-5">
				<JobDetails />
				<ApplicationForm />
			</div>
		</div>
	);
}
