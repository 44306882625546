import ACC from "../assets/brands/ACC.png";
import AECOM from "../assets/brands/AECOM-Logo.png";
import APGENCO from "../assets/brands/APGENCO.png";
import APHMEL from "../assets/brands/APHMEL.jpg";
import BHUSHAN_STEEL from "../assets/brands/Bhushan-Steel-logo-01.png";
import BPCL from "../assets/brands/BPCL.jpg";
import CCI from "../assets/brands/CCI.png";
import CEC from "../assets/brands/CEC-logo.png";
import CONTAINER_CORPORATION_OF_INDIA from "../assets/brands/Container_Corporation_of_India_logo.png";
import DALMIA from "../assets/brands/Dalmia.png";
import ELECTROSTEEL from "../assets/brands/electrosteel.avif";
import HP from "../assets/brands/HP.png";
import INDIA_CEMENTS_LOGO from "../assets/brands/India_Cements_logo.png";
import JINDAL_STEEL_AND_POWER from "../assets/brands/Jindal_Steel_and_Power.png";
import JSL from "../assets/brands/JSL.jpg";
import KARNATAKA_FOREST from "../assets/brands/Karnataka-Forest-Logo.png";
import KPC from "../assets/brands/KPC.jpg";
import KRISHNAPATNAM_PORT from "../assets/brands/Krishnapatnam port.jpg";
import KERALA_FOREST from "../assets/brands/Logo_of_Kerala_Forest_Department.png";
import MAHAGENCO_MAHARASHTRA_POWER_GENERATION from "../assets/brands/mahagenco-maharashtra-state-power-generation-co-ltd-logo-vector.png";
import MCLTD from "../assets/brands/MC ltd.jpg";
import NHIDCL from "../assets/brands/NHIDCL.png";
import NTPC from "../assets/brands/NTPC.png";
import PARADEEP_PHOSPHATES from "../assets/brands/paradeep-phosphates-limited--600.png";
import PORT_OF_CHENNAI from "../assets/brands/Port of Chennai.png";
import PRODIP_PORT_TRUST from "../assets/brands/Prodip-port-trust.jpg";
import SCCL from "../assets/brands/sccl-logo.jpeg";
import TATA_STEEL from "../assets/brands/tata steel.jpg";
import TERRATEC from "../assets/brands/terratec.png";
import THGM from "../assets/brands/THGM co.ltd.jpg";
import TSGENCO from "../assets/brands/TSGENCOlogo.png";
import UPRVUNL from "../assets/brands/uprvunl.png";
import VIZAG_STEEL from "../assets/brands/VIZAG-STEEL.jpg";
import VPT from "../assets/brands/vpt.webp";
import WBPDCL from "../assets/brands/wbpdcl.png";

export const clients = [
	{
		brand_name: "ACC",
		brand_img: ACC,
	},
	{
		brand_name: "AECOM",
		brand_img: AECOM,
	},
	{
		brand_name: "APGENCO",
		brand_img: APGENCO,
	},
	{
		brand_name: "APHMEL",
		brand_img: APHMEL,
	},
	{
		brand_name: "Bhushan Steel",
		brand_img: BHUSHAN_STEEL,
	},
	{
		brand_name: "BPCL",
		brand_img: BPCL,
	},
	{
		brand_name: "CCI",
		brand_img: CCI,
	},
	{
		brand_name: "CEC",
		brand_img: CEC,
	},
	{
		brand_name: "Container Corporation of India",
		brand_img: CONTAINER_CORPORATION_OF_INDIA,
	},
	{
		brand_name: "Dalmia",
		brand_img: DALMIA,
	},
	{
		brand_name: "Electrosteel",
		brand_img: ELECTROSTEEL,
	},
	{
		brand_name: "HP",
		brand_img: HP,
	},
	{
		brand_name: "India Cements",
		brand_img: INDIA_CEMENTS_LOGO,
	},
	{
		brand_name: "Jindal Steel and Power",
		brand_img: JINDAL_STEEL_AND_POWER,
	},
	{
		brand_name: "JSL",
		brand_img: JSL,
	},
	{
		brand_name: "Karnataka Forest",
		brand_img: KARNATAKA_FOREST,
	},
	{
		brand_name: "KPC",
		brand_img: KPC,
	},
	{
		brand_name: "Krishnapatnam Port",
		brand_img: KRISHNAPATNAM_PORT,
	},
	{
		brand_name: "Kerala Forest",
		brand_img: KERALA_FOREST,
	},
	{
		brand_name: "Mahagenco",
		brand_img: MAHAGENCO_MAHARASHTRA_POWER_GENERATION,
	},
	{
		brand_name: "MC Ltd",
		brand_img: MCLTD,
	},
	{
		brand_name: "NHIDCL",
		brand_img: NHIDCL,
	},
	{
		brand_name: "NTPC",
		brand_img: NTPC,
	},
	{
		brand_name: "Paradeep Phosphates",
		brand_img: PARADEEP_PHOSPHATES,
	},
	{
		brand_name: "Port of Chennai",
		brand_img: PORT_OF_CHENNAI,
	},
	{
		brand_name: "Prodip Port Trust",
		brand_img: PRODIP_PORT_TRUST,
	},
	{
		brand_name: "SCCL",
		brand_img: SCCL,
	},
	{
		brand_name: "Tata Steel",
		brand_img: TATA_STEEL,
	},
	{
		brand_name: "Terratec",
		brand_img: TERRATEC,
	},
	{
		brand_name: "THGM Co. Ltd",
		brand_img: THGM,
	},
	{
		brand_name: "TSGENCO",
		brand_img: TSGENCO,
	},
	{
		brand_name: "UPRVUNL",
		brand_img: UPRVUNL,
	},
	{
		brand_name: "Vizag Steel",
		brand_img: VIZAG_STEEL,
	},
	{
		brand_name: "VPT",
		brand_img: VPT,
	},
	{
		brand_name: "WBPDCL",
		brand_img: WBPDCL,
	},
];
