import React, { useState, useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { COMPANY_NAME, LOGO1 } from "../utils/constant";
import { motion } from "framer-motion";

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const navLinks = [
		{ name: "Home", path: "/" },
		{ name: "About Us", path: "/about-us" },
		{ name: "Projects", path: "/projects" },
		{ name: "Contact Us", path: "/contact-us" },
		{ name: "Join Us", path: "/careers" },
	];

	const handleOnClick = () => {
		setIsOpen(!isOpen);
	};

	const listVariants = {
		hidden: {
			opacity: 0,
			x: 50,
		},
		visible: (i) => ({
			opacity: 1,
			x: 0,
			transition: {
				delay: i * 0.1,
				duration: 0.5,
				ease: "easeInOut",
			},
		}),
	};

	return (
		<header className="w-full fixed flex justify-between items-center px-5 md:px-10 py-2 hero-section lg:overflow-hidden z-50 bg-[#4682B4]">
			<div className="">
				<Link to="/" className="flex justify-center items-center">
					<img
						src={LOGO1}
						alt="Sumcon Infraventures"
						className="w-16 h-16"
					/>
					<span className="capitalize text-4xl font-semibold hidden md:flex text-white">
						{COMPANY_NAME}
					</span>
				</Link>
			</div>
			<nav className="hidden lg:block" ref={ref}>
				<ul className="flex gap-6 items-center text-[#ffffff] text-xl font-medium">
					{navLinks.map((item, index) => (
						<li
							className="relative cursor-pointer group capitalize text-white"
							key={index}
						>
							<NavLink
								to={item.path}
								className={({ isActive }) =>
									isActive
										? "font-semibold border-b-[3px] border-white capitalize"
										: ""
								}
							>
								{item.name}
								<span className="absolute left-1/2 top-[27.5px] w-0 h-[2.8px] bg-white transition-all duration-500 ease-in-out group-hover:w-full group-hover:left-0"></span>
							</NavLink>
						</li>
					))}
				</ul>
			</nav>

			<div className="lg:hidden">
				<div
					className={`${isOpen ? "hidden" : "block"}`}
					onClick={handleOnClick}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="icon icon-tabler icon-tabler-menu-2"
						width="40"
						height="40"
						viewBox="0 0 24 24"
						strokeWidth="2"
						stroke="#ffffff"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path stroke="none" d="M0 0h24v24H0z" fill="none" />
						<path d="M4 6l16 0" />
						<path d="M4 12l16 0" />
						<path d="M4 18l16 0" />
					</svg>
				</div>

				<div
					onClick={handleOnClick}
					className={`${isOpen ? "block" : "hidden"}`}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="icon icon-tabler icon-tabler-letter-x"
						width="40"
						height="40"
						viewBox="0 0 24 24"
						strokeWidth="2"
						stroke="#ffffff"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path stroke="none" d="M0 0h24v24H0z" fill="none" />
						<path d="M7 4l10 16" />
						<path d="M17 4l-10 16" />
					</svg>
				</div>
			</div>

			<div
				className={`w-2/3 sm:w-1/3 pt-5 flex flex-col items-end justify-start absolute top-[80px] right-0 min-h-screen bg-white transition-transform duration-500 ease-in-out ${
					isOpen ? "translate-x-0" : "translate-x-full"
				}`}
			>
				<div className="w-full flex justify-center items-center">
					<ul className="flex flex-col items-start gap-3">
						{navLinks.map((item, index) => {
							return (
								<motion.li
									key={index}
									initial="hidden"
									custom={index}
									animate={isOpen ? "visible" : "hidden"}
									variants={listVariants}
									className="text-2xl font-medium text-[#4682B4]"
								>
									<NavLink to={item.path}>
										{item.name}
									</NavLink>
								</motion.li>
							);
						})}
					</ul>
				</div>
			</div>
		</header>
	);
};

export default Navbar;
