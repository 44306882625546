import { useState } from "react";
import "react-phone-number-input/style.css";
import "../styles/PhoneInputStyles.css";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import { Formik, Form } from "formik";
import axios from "axios";
import Error from "./Error";
import validationSchemas from "../schema/applicationValidationSchema";

export default function PersonalDetails({ initialValues, onNext, step }) {
	const [isPincodeValidating, setIsPincodeValidating] = useState(false);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemas[step]}
			onSubmit={(values) => {
				onNext(values);
			}}
		>
			{({
				values,
				errors,
				touched,
				handleBlur,
				handleChange,
				setFieldValue,
				setFieldTouched,
			}) => {
				const handleAddressCheckboxChange = (event) => {
					const isChecked = event.target.checked;
					setFieldValue("sameAsCurrentAddress", isChecked);

					if (isChecked) {
						setFieldValue(
							"permanentAddress",
							values.currentAddress
						);
					}
				};

				const handleCountryChange = (selectedOption) => {
					setFieldValue(
						"country",
						selectedOption ? selectedOption.isoCode : ""
					);
					setFieldValue("state", ""); // Reset state when country changes
					setFieldValue("district", ""); // Reset district when country changes
				};

				const handleStateChange = (selectedOption) => {
					setFieldValue(
						"state",
						selectedOption ? selectedOption.isoCode : ""
					);
					setFieldValue("district", ""); // Reset district when state changes
				};

				const handleCityChange = (selectedOption) => {
					setFieldValue(
						"district",
						selectedOption ? selectedOption.name : ""
					);
				};

				const countryOptions = Country.getAllCountries().map(
					(country) => ({
						value: country.isoCode,
						label: country.name,
						...country,
					})
				);

				const stateOptions = values.country
					? State.getStatesOfCountry(values.country).map((state) => ({
							value: state.isoCode,
							label: state.name,
							...state,
					  }))
					: [];

				const cityOptions = values.state
					? City.getCitiesOfState(values.country, values.state).map(
							(city) => ({
								value: city.name,
								label: city.name,
								...city,
							})
					  )
					: [];

				const handlePinChange = (e) => {
					let debounceTimeout;
					const pinCode = e.target.value; // Correct field extraction
					setFieldValue("pinCode", pinCode); // Update field value immediately
					setFieldValue("pinCodeError", ""); // Clear any previous error message

					// Check if the PIN code is of valid length
					if (pinCode.length !== 6) {
						setFieldValue(
							"pinCodeError",
							"PIN code must be 6 digits"
						);
						return;
					}

					// Debounce validation
					if (debounceTimeout) clearTimeout(debounceTimeout);
					debounceTimeout = setTimeout(async () => {
						try {
							setIsPincodeValidating(true);
							const response = await axios.get(
								`https://api.postalpincode.in/pincode/${pinCode}`
							);

							const result = response.data[0];
							if (result?.Status === "Success") {
								setFieldValue("pinCodeError", ""); // Clear error
								setFieldValue("pinCode", pinCode);
							} else {
								setFieldValue(
									"pinCodeError",
									"Invalid PIN code"
								);
							}
						} catch (error) {
							setFieldValue(
								"pinCodeError",
								"Error validating PIN code"
							);
						} finally {
							setIsPincodeValidating(false);
						}
					}, 500);
				};

				return (
					<Form className="space-y-4">
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
							{/* First Name */}
							<div className="col-span-2 sm:col-span-1">
								<label
									htmlFor="firstName"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									First Name
								</label>
								<input
									type="text"
									id="firstName"
									name="firstName"
									placeholder="e.g. John"
									value={values.firstName}
									onChange={handleChange}
									onBlur={handleBlur}
									className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
										errors.firstName && touched.firstName
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.firstName && touched.firstName && (
									<Error msg={errors.firstName} />
								)}
							</div>

							{/* lastName */}
							<div className="col-span-2 sm:col-span-1">
								<label
									htmlFor="lastName"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Last Name
								</label>
								<input
									type="text"
									id="lastName"
									name="lastName"
									placeholder="e.g. Doe"
									value={values.lastName}
									onChange={handleChange}
									onBlur={handleBlur}
									className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
										errors.lastName && touched.lastName
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.lastName && touched.lastName && (
									<Error msg={errors.lastName} />
								)}
							</div>

							{/* email */}
							<div className="col-span-2 sm:col-span-1">
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Email
								</label>
								<input
									type="email"
									id="email"
									name="email"
									placeholder="e.g. example@domain.com"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
										errors.email && touched.email
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.email && touched.email && (
									<Error msg={errors.email} />
								)}
							</div>

							{/* phone */}
							<div className="col-span-2 sm:col-span-1">
								<label
									htmlFor="phone"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Phone
								</label>
								<PhoneInput
									id="phone"
									name="phone"
									placeholder="e.g. 234 567 890"
									value={values.phone}
									onChange={(value) =>
										setFieldValue("phone", value || "")
									}
									onBlur={() =>
										setFieldTouched("phone", true)
									}
									className={`phone-input-container w-full py-1 px-4 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
										errors.phone && touched.phone
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.phone && touched.phone && (
									<Error msg={errors.phone} />
								)}
							</div>

							{/* currentAddress */}
							<div className="col-span-2">
								<label
									htmlFor="currentAddress"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Current Address
								</label>
								<input
									type="text"
									id="currentAddress"
									name="currentAddress"
									placeholder="House No, Street, City"
									value={values.currentAddress}
									onChange={handleChange}
									onBlur={handleBlur}
									className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
										errors.currentAddress &&
										touched.currentAddress
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.currentAddress &&
									touched.currentAddress && (
										<Error msg={errors.currentAddress} />
									)}
							</div>

							{/* Checkbox for Same Address */}
							<div className="col-span-2 flex items-center gap-2">
								<input
									type="checkbox"
									id="sameAsCurrentAddress"
									checked={
										values.sameAsCurrentAddress || false
									}
									onChange={handleAddressCheckboxChange}
									className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
								/>
								<label
									htmlFor="sameAsCurrentAddress"
									className="text-sm font-medium text-gray-700"
								>
									Same as Current Address
								</label>
							</div>

							{/* Permanent Address */}
							<div className="col-span-2">
								<label
									htmlFor="permanentAddress"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Permanent Address
								</label>
								<input
									type="text"
									id="permanentAddress"
									name="permanentAddress"
									placeholder="House No, Street, City"
									value={values.permanentAddress}
									onChange={handleChange}
									onBlur={handleBlur}
									disabled={values.sameAsCurrentAddress}
									className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
										errors.permanentAddress &&
										touched.permanentAddress
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.permanentAddress &&
									touched.permanentAddress && (
										<Error msg={errors.permanentAddress} />
									)}
							</div>

							{/* Country */}
							<div className="col-span-2 sm:col-span-1">
								<label
									htmlFor="country"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Country
								</label>
								<Select
									id="country"
									name="country"
									placeholder="Select your country"
									options={countryOptions}
									value={
										values.country === ""
											? countryOptions.find(
													(option) =>
														option.name ===
														values.country
											  )
											: countryOptions.find(
													(option) =>
														option.isoCode ===
														values.country
											  )
									}
									onBlur={handleBlur}
									onChange={handleCountryChange}
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.country && touched.country
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.country && touched.country && (
									<Error msg={errors.country} />
								)}
							</div>

							{/* State */}
							<div className="col-span-2 sm:col-span-1">
								<label
									htmlFor="state"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									State
								</label>
								<Select
									id="state"
									name="state"
									placeholder="Select your state"
									options={stateOptions}
									value={
										values.country === ""
											? stateOptions?.find(
													(option) =>
														option.name ===
														values.state
											  )
											: stateOptions?.find(
													(option) =>
														option.isoCode ===
														values.state
											  )
									}
									onChange={handleStateChange}
									isDisabled={!values.country} // Disable if no country is selected
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.state && touched.state
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
									onBlur={handleBlur}
								/>
								{errors.state && touched.state && (
									<Error msg={errors.state} />
								)}
							</div>

							{/* District (City) */}
							<div className="col-span-2 sm:col-span-1">
								<label
									htmlFor="district"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									District
								</label>
								<Select
									id="district"
									name="district"
									placeholder="Select your district"
									options={cityOptions}
									value={cityOptions?.find(
										(option) =>
											option.name === values.district
									)}
									onChange={handleCityChange}
									isDisabled={!values.state} // Disable if no state is selected
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.district && touched.district
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
									onBlur={handleBlur}
								/>
								{errors.district && touched.district && (
									<Error msg={errors.district} />
								)}
							</div>

							{/* PIN Code */}
							<div className="col-span-2 sm:col-span-1">
								<label
									htmlFor="pinCode"
									className="block text-sm font-medium text-gray-700 mb-2"
								>
									PIN Code{" "}
									<span className="text-red-500">*</span>
								</label>
								<input
									type="text"
									id="pinCode"
									name="pinCode"
									placeholder="Enter your PIN code"
									value={values.pinCode}
									onChange={(e) => {
										handlePinChange(e);
									}}
									maxLength={6}
									onBlur={handleBlur}
									className={`w-full py-2 px-4 border rounded-md ${
										errors.pinCode && touched.pinCode
											? "border-red-500"
											: "border-gray-300"
									}`}
								/>
								{isPincodeValidating && (
									<p className="text-sm text-blue-500">
										Validating PIN code...
									</p>
								)}
								{errors.pinCode && touched.pinCode && (
									<Error msg={errors.pinCode} />
								)}
							</div>
						</div>
						<div className="flex justify-between mt-6">
							<button
								type="button"
								disabled={true}
								className="px-4 py-2 border rounded-md bg-gray-100 disabled:opacity-50"
							>
								Previous
							</button>
							<button
								type="submit"
								className="px-4 py-2 border rounded-md bg-blue-600 text-white hover:bg-blue-700"
							>
								Next
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
}
