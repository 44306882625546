export const degreeOptions = [
	{ value: "bachelor_of_arts", label: "Bachelor of Arts (BA)" },
	{ value: "bachelor_of_science", label: "Bachelor of Science (BSc)" },
	{ value: "bachelor_of_commerce", label: "Bachelor of Commerce (BCom)" },
	{ value: "bachelor_of_engineering", label: "Bachelor of Engineering (BE)" },
	{
		value: "bachelor_of_technology",
		label: "Bachelor of Technology (BTech)",
	},
	{
		value: "bachelor_of_computer_applications",
		label: "Bachelor of Computer Applications (BCA)",
	},
	{
		value: "bachelor_of_business_administration",
		label: "Bachelor of Business Administration (BBA)",
	},
	{ value: "bachelor_of_education", label: "Bachelor of Education (BEd)" },
	{ value: "bachelor_of_laws", label: "Bachelor of Laws (LLB)" },
	{
		value: "bachelor_of_medicine_and_surgery",
		label: "Bachelor of Medicine and Surgery (MBBS)",
	},
	{
		value: "bachelor_of_dental_surgery",
		label: "Bachelor of Dental Surgery (BDS)",
	},
	{ value: "bachelor_of_pharmacy", label: "Bachelor of Pharmacy (BPharm)" },
	{
		value: "bachelor_of_architecture",
		label: "Bachelor of Architecture (BArch)",
	},
	{ value: "master_of_arts", label: "Master of Arts (MA)" },
	{ value: "master_of_science", label: "Master of Science (MSc)" },
	{ value: "master_of_commerce", label: "Master of Commerce (MCom)" },
	{ value: "master_of_technology", label: "Master of Technology (MTech)" },
	{
		value: "master_of_business_administration",
		label: "Master of Business Administration (MBA)",
	},
	{
		value: "master_of_computer_applications",
		label: "Master of Computer Applications (MCA)",
	},
	{ value: "master_of_education", label: "Master of Education (MEd)" },
	{ value: "master_of_laws", label: "Master of Laws (LLM)" },
	{ value: "master_of_philosophy", label: "Master of Philosophy (MPhil)" },
	{ value: "doctor_of_philosophy", label: "Doctor of Philosophy (PhD)" },
	{ value: "diploma", label: "Diploma" },
	{ value: "postgraduate_diploma", label: "Postgraduate Diploma" },
	{ value: "certificate_course", label: "Certificate Course" },
	{ value: "associate_degree", label: "Associate Degree" },
	{
		value: "doctor_of_medical_science",
		label: "Doctor of Medical Science (DM)",
	},
	{ value: "doctor_of_pharmacy", label: "Doctor of Pharmacy (PharmD)" },
	{ value: "master_of_fine_arts", label: "Master of Fine Arts (MFA)" },
	{ value: "master_of_design", label: "Master of Design (MDes)" },
	{ value: "master_of_management", label: "Master of Management (MM)" },
	{ value: "bachelor_of_design", label: "Bachelor of Design (BDes)" },
	{ value: "bachelor_of_fine_arts", label: "Bachelor of Fine Arts (BFA)" },
	{ value: "doctor_of_pharmacy", label: "Doctor of Pharmacy (PharmD)" },
	{ value: "postgraduate_certificate", label: "Postgraduate Certificate" },
	{ label: "Others", value: "others" },
];

export const fieldOfStudyOptions = [
	{ value: "computer_science", label: "Computer Science" },
	{ value: "information_technology", label: "Information Technology" },
	{ value: "electrical_engineering", label: "Electrical Engineering" },
	{ value: "mechanical_engineering", label: "Mechanical Engineering" },
	{ value: "civil_engineering", label: "Civil Engineering" },
	{
		value: "electronics_communication",
		label: "Electronics & Communication Engineering",
	},
	{ value: "chemical_engineering", label: "Chemical Engineering" },
	{ value: "biotechnology", label: "Biotechnology" },
	{ value: "biomedical_engineering", label: "Biomedical Engineering" },
	{ value: "aerospace_engineering", label: "Aerospace Engineering" },
	{ value: "marine_engineering", label: "Marine Engineering" },
	{ value: "agriculture", label: "Agriculture" },
	{ value: "architecture", label: "Architecture" },
	{ value: "business_administration", label: "Business Administration" },
	{ value: "finance", label: "Finance" },
	{ value: "marketing", label: "Marketing" },
	{ value: "human_resources", label: "Human Resources" },
	{ value: "economics", label: "Economics" },
	{ value: "accounting", label: "Accounting" },
	{ value: "international_business", label: "International Business" },
	{ value: "logistics_management", label: "Logistics Management" },
	{ value: "tourism", label: "Tourism Management" },
	{ value: "hotel_management", label: "Hotel Management" },
	{ value: "retail_management", label: "Retail Management" },
	{ value: "law", label: "Law" },
	{ value: "medicine", label: "Medicine" },
	{ value: "dentistry", label: "Dentistry" },
	{ value: "nursing", label: "Nursing" },
	{ value: "pharmacy", label: "Pharmacy" },
	{ value: "psychology", label: "Psychology" },
	{ value: "sociology", label: "Sociology" },
	{ value: "education", label: "Education" },
	{ value: "history", label: "History" },
	{ value: "political_science", label: "Political Science" },
	{ value: "literature", label: "Literature" },
	{ value: "philosophy", label: "Philosophy" },
	{ value: "performing_arts", label: "Performing Arts" },
	{ value: "visual_arts", label: "Visual Arts" },
	{ value: "sports_science", label: "Sports Science" },
	{ value: "journalism", label: "Journalism" },
	{ value: "mass_communication", label: "Mass Communication" },
	{ value: "music", label: "Music" },
	{ value: "fashion_design", label: "Fashion Design" },
	{ value: "interior_design", label: "Interior Design" },
	{ value: "data_science", label: "Data Science" },
	{ value: "artificial_intelligence", label: "Artificial Intelligence" },
	{ value: "machine_learning", label: "Machine Learning" },
	{ value: "robotics", label: "Robotics" },
	{ value: "veterinary_science", label: "Veterinary Science" },
	{ value: "environmental_science", label: "Environmental Science" },
	{ value: "climate_change", label: "Climate Change" },
	{ value: "sustainable_development", label: "Sustainable Development" },
	{ value: "public_health", label: "Public Health" },
	{ value: "urban_planning", label: "Urban Planning" },
	{ value: "forensic_science", label: "Forensic Science" },
	{ value: "criminology", label: "Criminology" },
	{ value: "library_science", label: "Library Science" },
	{ value: "archaeology", label: "Archaeology" },
	{ value: "space_science", label: "Space Science" },
	{ value: "astronomy", label: "Astronomy" },
	{ value: "sports_management", label: "Sports Management" },
	{ value: "geology", label: "Geology" },
	{ label: "Others", value: "others" },
];

export const institutionsOptions = [
	{ label: "Indian Institute of Technology Bombay", value: "iit_bombay" },
	{ label: "Indian Institute of Technology Delhi", value: "iit_delhi" },
	{ label: "Indian Institute of Technology Kanpur", value: "iit_kanpur" },
	{ label: "Indian Institute of Technology Madras", value: "iit_madras" },
	{ label: "Indian Institute of Science Bangalore", value: "iisc_bangalore" },
	{ label: "Jawaharlal Nehru University", value: "jnu_delhi" },
	{ label: "University of Delhi", value: "university_of_delhi" },
	{
		label: "All India Institute of Medical Sciences Delhi",
		value: "aiims_delhi",
	},
	{ label: "Banaras Hindu University", value: "bhu_varanasi" },
	{ label: "University of Calcutta", value: "university_of_calcutta" },
	{ label: "Anna University", value: "anna_university" },
	{ label: "Amity University", value: "amity_university" },
	{
		label: "Indian Institute of Management Ahmedabad",
		value: "iim_ahmedabad",
	},
	{
		label: "Indian Institute of Management Bangalore",
		value: "iim_bangalore",
	},
	{ label: "National Institute of Technology Trichy", value: "nit_trichy" },
	{
		label: "Birla Institute of Technology and Science Pilani",
		value: "bits_pilani",
	},
	{ label: "Indian School of Business Hyderabad", value: "isb_hyderabad" },
	{ label: "Savitribai Phule Pune University", value: "pune_university" },
	{ label: "Jadavpur University", value: "jadavpur_university" },
	{ label: "Visva-Bharati University", value: "visva_bharati" },
	{ label: "Jamia Millia Islamia", value: "jamia_millia_islamia" },
	{ label: "Aligarh Muslim University", value: "amu_aligarh" },
	{
		label: "Manipal Academy of Higher Education",
		value: "manipal_university",
	},
	{ label: "Shiv Nadar University", value: "shiv_nadar_university" },
	{ label: "Vellore Institute of Technology", value: "vit_vellore" },
	{
		label: "SRM Institute of Science and Technology",
		value: "srm_university",
	},
	{ label: "Lovely Professional University", value: "lpu_punjab" },
	{ label: "Chandigarh University", value: "chandigarh_university" },
	{ label: "Guru Nanak Dev University", value: "gndu_amritsar" },
	{
		label: "Indian Agricultural Research Institute",
		value: "iari_new_delhi",
	},
	{ label: "Tata Institute of Social Sciences", value: "tiss_mumbai" },
	{ label: "Osmania University", value: "osmania_university" },
	{ label: "Maharaja Sayajirao University of Baroda", value: "msu_baroda" },
	{
		label: "Symbiosis International University",
		value: "symbiosis_university",
	},
	{ label: "Sikkim Manipal University", value: "sikkim_manipal_university" },
	{
		label: "Narsee Monjee Institute of Management Studies",
		value: "nmims_mumbai",
	},
	{ label: "Christ University", value: "christ_university" },
	{ label: "Indira Gandhi National Open University", value: "ignou" },
	{ label: "Ashoka University", value: "ashoka_university" },
	{ label: "OP Jindal Global University", value: "op_jindal_university" },
	{ label: "Indian Institute of Technology Roorkee", value: "iit_roorkee" },
	{ label: "Indian Institute of Technology Guwahati", value: "iit_guwahati" },
	{
		label: "Indian Institute of Technology Hyderabad",
		value: "iit_hyderabad",
	},
	{ label: "Indian Institute of Technology Indore", value: "iit_indore" },
	{ label: "National Institute of Fashion Technology", value: "nift_delhi" },
	{
		label: "National Law School of India University",
		value: "nlsiu_bangalore",
	},
	{ label: "Homi Bhabha National Institute", value: "hbni_mumbai" },
	{ label: "Indian Statistical Institute Kolkata", value: "isi_kolkata" },
	{ label: "Indian Maritime University", value: "imu_chennai" },
	{
		label: "Dr. APJ Abdul Kalam Technical University",
		value: "aktu_lucknow",
	},
	{ label: "Others", value: "others" },
];

export const graduationMonthOptions = [
	{ value: "January", label: "January" },
	{ value: "February", label: "February" },
	{ value: "March", label: "March" },
	{ value: "April", label: "April" },
	{ value: "May", label: "May" },
	{ value: "June", label: "June" },
	{ value: "July", label: "July" },
	{ value: "August", label: "August" },
	{ value: "September", label: "September" },
	{ value: "October", label: "October" },
	{ value: "November", label: "November" },
	{ value: "December", label: "December" },
];

export const graduationYearOptions = [
	{ value: "1965", label: "1965" },
	{ value: "1966", label: "1966" },
	{ value: "1967", label: "1967" },
	{ value: "1968", label: "1968" },
	{ value: "1969", label: "1969" },
	{ value: "1970", label: "1970" },
	{ value: "1971", label: "1971" },
	{ value: "1972", label: "1972" },
	{ value: "1973", label: "1973" },
	{ value: "1974", label: "1974" },
	{ value: "1975", label: "1975" },
	{ value: "1976", label: "1976" },
	{ value: "1977", label: "1977" },
	{ value: "1978", label: "1978" },
	{ value: "1979", label: "1979" },
	{ value: "1980", label: "1980" },
	{ value: "1981", label: "1981" },
	{ value: "1982", label: "1982" },
	{ value: "1983", label: "1983" },
	{ value: "1984", label: "1984" },
	{ value: "1985", label: "1985" },
	{ value: "1986", label: "1986" },
	{ value: "1987", label: "1987" },
	{ value: "1988", label: "1988" },
	{ value: "1989", label: "1989" },
	{ value: "1990", label: "1990" },
	{ value: "1991", label: "1991" },
	{ value: "1992", label: "1992" },
	{ value: "1993", label: "1993" },
	{ value: "1994", label: "1994" },
	{ value: "1995", label: "1995" },
	{ value: "1996", label: "1996" },
	{ value: "1997", label: "1997" },
	{ value: "1998", label: "1998" },
	{ value: "1999", label: "1999" },
	{ value: "2000", label: "2000" },
	{ value: "2001", label: "2001" },
	{ value: "2002", label: "2002" },
	{ value: "2003", label: "2003" },
	{ value: "2004", label: "2004" },
	{ value: "2005", label: "2005" },
	{ value: "2006", label: "2006" },
	{ value: "2007", label: "2007" },
	{ value: "2008", label: "2008" },
	{ value: "2009", label: "2009" },
	{ value: "2010", label: "2010" },
	{ value: "2011", label: "2011" },
	{ value: "2012", label: "2012" },
	{ value: "2013", label: "2013" },
	{ value: "2014", label: "2014" },
	{ value: "2015", label: "2015" },
	{ value: "2016", label: "2016" },
	{ value: "2017", label: "2017" },
	{ value: "2018", label: "2018" },
	{ value: "2019", label: "2019" },
	{ value: "2020", label: "2020" },
	{ value: "2021", label: "2021" },
	{ value: "2022", label: "2022" },
	{ value: "2023", label: "2023" },
	{ value: "2024", label: "2024" },
];

export const universitiesOptions = [
	{ label: "University of Delhi", value: "university_of_delhi" },
	{ label: "Jawaharlal Nehru University (JNU)", value: "jnu" },
	{ label: "Banaras Hindu University (BHU)", value: "bhu" },
	{ label: "University of Calcutta", value: "university_of_calcutta" },
	{ label: "University of Mumbai", value: "university_of_mumbai" },
	{ label: "Savitribai Phule Pune University", value: "pune_university" },
	{ label: "Anna University", value: "anna_university" },
	{ label: "Jadavpur University", value: "jadavpur_university" },
	{ label: "University of Madras", value: "university_of_madras" },
	{ label: "Osmania University", value: "osmania_university" },
	{ label: "Gujarat University", value: "gujarat_university" },
	{ label: "Kerala University", value: "kerala_university" },
	{ label: "Bangalore University", value: "bangalore_university" },
	{ label: "Panjab University", value: "panjab_university" },
	{ label: "Rajasthan University", value: "rajasthan_university" },
	{ label: "Lucknow University", value: "lucknow_university" },
	{ label: "Patna University", value: "patna_university" },
	{ label: "Shivaji University", value: "shivaji_university" },
	{ label: "Nagpur University", value: "nagpur_university" },
	{ label: "North-Eastern Hill University (NEHU)", value: "nehu" },
	{ label: "Tezpur University", value: "tezpur_university" },
	{ label: "Dr. Babasaheb Ambedkar Marathwada University", value: "bamu" },
	{ label: "Visva-Bharati University", value: "visva_bharati_university" },
	{ label: "Mizoram University", value: "mizoram_university" },
	{ label: "Goa University", value: "goa_university" },
	{ label: "Manipal Academy of Higher Education (MAHE)", value: "mahe" },
	{ label: "Pondicherry University", value: "pondicherry_university" },
	{ label: "Guru Nanak Dev University", value: "gndu" },
	{ label: "Vellore Institute of Technology (VIT)", value: "vit" },
	{ label: "SRM Institute of Science and Technology", value: "srm" },
	{ label: "Chandigarh University", value: "chandigarh_university" },
	{ label: "Bharathiar University", value: "bharathiar_university" },
	{ label: "Utkal University", value: "utkal_university" },
	{ label: "Barkatullah University", value: "barkatullah_university" },
	{ label: "Calicut University", value: "calicut_university" },
	{
		label: "Madurai Kamaraj University",
		value: "madurai_kamaraj_university",
	},
	{ label: "Amity University", value: "amity_university" },
	{
		label: "Birla Institute of Technology and Science (BITS Pilani)",
		value: "bits_pilani",
	},
	{ label: "Mangalore University", value: "mangalore_university" },
	{ label: "Sikkim Manipal University", value: "sikkim_manipal_university" },
	{ label: "Jamia Millia Islamia", value: "jamia_millia_islamia" },
	{ label: "Aligarh Muslim University (AMU)", value: "amu" },
	{
		label: "Indian Institute of Science (IISc) Bangalore",
		value: "iisc_bangalore",
	},
	{
		label: "Indian Institute of Technology Bombay (IIT Bombay)",
		value: "iit_bombay",
	},
	{
		label: "Indian Institute of Technology Delhi (IIT Delhi)",
		value: "iit_delhi",
	},
	{
		label: "Indian Institute of Technology Madras (IIT Madras)",
		value: "iit_madras",
	},
	{
		label: "Indian Institute of Technology Kanpur (IIT Kanpur)",
		value: "iit_kanpur",
	},
	{
		label: "Indian Institute of Technology Kharagpur (IIT Kharagpur)",
		value: "iit_kharagpur",
	},
	{
		label: "Indian Institute of Technology Roorkee (IIT Roorkee)",
		value: "iit_roorkee",
	},
	{
		label: "Indian Institute of Management Ahmedabad (IIM Ahmedabad)",
		value: "iim_ahmedabad",
	},
	{
		label: "Indian Institute of Management Bangalore (IIM Bangalore)",
		value: "iim_bangalore",
	},
	{
		label: "Indian Institute of Management Calcutta (IIM Calcutta)",
		value: "iim_calcutta",
	},
	{
		label: "Indian Institute of Management Kozhikode (IIM Kozhikode)",
		value: "iim_kozhikode",
	},
	{
		label: "University of Agricultural Sciences Bangalore",
		value: "uas_bangalore",
	},
	{ label: "Acharya Nagarjuna University", value: "anu" },
	{ label: "Andhra University", value: "andhra_university" },
	{ label: "Kakatiya University", value: "kakatiya_university" },
	{ label: "Sri Venkateswara University", value: "svu" },
	{ label: "Annamalai University", value: "annamalai_university" },
	{
		label: "Avinashilingam Institute for Home Science and Higher Education for Women",
		value: "avinashilingam_university",
	},
	{ label: "Banasthali Vidyapith", value: "banasthali_vidyapith" },
	{
		label: "Central University of Haryana",
		value: "central_university_of_haryana",
	},
	{
		label: "Central University of Jammu",
		value: "central_university_of_jammu",
	},
	{
		label: "Central University of Kerala",
		value: "central_university_of_kerala",
	},
	{
		label: "Central University of Odisha",
		value: "central_university_of_odisha",
	},
	{
		label: "Central University of Rajasthan",
		value: "central_university_of_rajasthan",
	},
	{
		label: "Central University of Tamil Nadu",
		value: "central_university_of_tamil_nadu",
	},
	{ label: "Devi Ahilya Vishwavidyalaya", value: "devi_ahilya_university" },
	{
		label: "Dr. Harisingh Gour Vishwavidyalaya",
		value: "dr_harisingh_gour_university",
	},
	{ label: "Dr. Ram Manohar Lohia Avadh University", value: "rmlau" },
	{
		label: "Guru Ghasidas Vishwavidyalaya",
		value: "guru_ghasidas_university",
	},
	{
		label: "Hemwati Nandan Bahuguna Garhwal University",
		value: "hnb_garhwal_university",
	},
	{
		label: "Indian Maritime University",
		value: "indian_maritime_university",
	},
	{ label: "Karnataka State Women’s University", value: "kswu" },
	{ label: "Mahatma Gandhi University", value: "mg_university" },
	{ label: "Nalanda University", value: "nalanda_university" },
	{ label: "National Law School of India University", value: "nlsiu" },
	{ label: "Sambalpur University", value: "sambalpur_university" },
	{ label: "Sardar Patel University", value: "sardar_patel_university" },
	{ label: "Sri Krishnadevaraya University", value: "sku" },
	{ label: "Tata Institute of Social Sciences", value: "tiss" },
	{ label: "The English and Foreign Languages University", value: "eflu" },
	{ label: "Others", value: "others" },
];

export const experienceOptions = [
	{ label: "Fresher", value: "fresher" },
	{ label: "Experienced", value: "experienced" },
];
