import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { jobs } from "../utils/jobs";

export default function JobDetails() {
	const { id } = useParams();
	// const navigate = useNavigate();

	const job = jobs.find((job) => job.id === parseInt(id, 10));

	if (!job) {
		return (
			<p className="text-center text-gray-600 text-lg">Job not found!</p>
		);
	}

	return (
		<div className="flex justify-center items-center flex-col">
			<h2 className="text-3xl font-semibold mb-5 capitalize">
				Apply for {job.title} role
			</h2>
			<div className="space-y-4">
				<p>
					<strong>Department:</strong> {job.department}
				</p>
				<p>
					<strong>Location:</strong> {job.location}
				</p>
				<p>
					<strong>Job Type:</strong> {job.type}
				</p>
				<p>
					<strong>Posted:</strong> {job.postedDate}
				</p>
				<p>
					<strong>Experience:</strong> {job.experience}
				</p>
				<div>
					<h3 className="font-semibold mb-2">Job Description</h3>
					<p>{job.description}</p>
				</div>
			</div>
		</div>
	);
}
