import React from "react";

function Card(data) {
	return (
		<div className="w-full max-w-sm h-[400px] flex flex-col justify-center items-center rounded-lg shadow-md bg-white overflow-hidden">
			{/* Wrapper for the image and overlay */}
			<div className="w-full h-96 relative group overflow-hidden cursor-pointer">
				{/* Image */}
				<img
					src={data.data.image}
					alt="onsite projects"
					className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-125"
				/>
				{/* Overlay */}
				<div className="absolute inset-0 bg-black opacity-20"></div>
			</div>
			{/* Text */}
			<span className="font-serif font-semibold text-lg py-2 capitalize text-[#4682B4]">
				{data.data.site_name}
			</span>
		</div>
	);
}

export default Card;
