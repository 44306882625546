import React from "react";

const ClientCard = ({ data }) => {
	return (
		<div className="relative w-28 h-28 pb-5 rounded-md mx-10">
			<img
				src={data.brand_img}
				alt={data.brand_name}
				className="w-full h-30 rounded-md"
			/>
		</div>
	);
};

export default ClientCard;
