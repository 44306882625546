import * as yup from "yup";

const contactSchema = yup.object().shape({
	name: yup.string().required("Name is required"),
	email: yup
		.string()
		.email("Invalid email address")
		.required("Email is required"),
	phone_number: yup
		.string()
		.matches(/^\d{10}$/, "Invalid phone number")
		.optional(),
	message: yup.string().required("Message is required"),
});

export default contactSchema;
