import { useState } from "react";
import { Formik, Form } from "formik";
import Select from "react-select";
import {
	degreeOptions,
	fieldOfStudyOptions,
	graduationMonthOptions,
	graduationYearOptions,
	institutionsOptions,
	universitiesOptions,
} from "../utils/education";
import validationSchemas from "../schema/applicationValidationSchema";
import Error from "./Error";

export default function Education({ initialValues, onNext, onBack, step }) {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [skills, setSkills] = useState(initialValues.skills || []); // Initialize skills from formik values
	const [currentSkill, setCurrentSkill] = useState(""); // Track input value for a new skill

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemas[step]}
			onSubmit={(values) => {
				onNext(values);
			}}
		>
			{({
				errors,
				touched,
				values,
				setFieldValue,
				handleBlur,
				handleChange,
				setFieldTouched,
			}) => {
				// Add a skill
				const handleAddSkill = () => {
					if (
						currentSkill.trim() &&
						!skills.includes(currentSkill.trim())
					) {
						const updatedSkills = [...skills, currentSkill.trim()];
						setSkills(updatedSkills);
						setFieldValue("skills", updatedSkills); // Update formik field
						setCurrentSkill("");
					}
				};

				// Remove a skill
				const handleRemoveSkill = (skillToRemove) => {
					const updatedSkills = skills.filter(
						(skill) => skill !== skillToRemove
					);
					setSkills(updatedSkills);
					setFieldValue("skills", updatedSkills); // Update formik field
				};

				const handleUpload = (file) => {
					if (!file) return;

					// Reset progress
					setUploadProgress(0);

					// Simulate the upload process
					const interval = setInterval(() => {
						setUploadProgress((prev) => {
							if (prev >= 100) {
								clearInterval(interval);
								return 100;
							}
							return prev + 10; // Increment progress
						});
					}, 300); // Update every 300ms
				};

				// const customStyles = (field) => ({
				// 	control: (provided, state) => ({
				// 		...provided,
				// 		borderColor:
				// 			errors[field] && touched[field]
				// 				? "red"
				// 				: provided.borderColor,
				// 		"&:hover": {
				// 			borderColor:
				// 				errors[field] && touched[field]
				// 					? "red"
				// 					: provided.borderColor,
				// 		},
				// 		boxShadow:
				// 			errors[field] && touched[field]
				// 				? "0 0 0 1px red"
				// 				: provided.boxShadow,
				// 	}),
				// });

				console.log(values);

				return (
					<Form>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
							{/* Highest Degree */}
							<div>
								<label
									htmlFor="highestDegree"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Highest Degree
								</label>
								<Select
									id="highestDegree"
									name="highestDegree"
									placeholder="Select your degree"
									options={degreeOptions}
									value={
										values.highestDegree === ""
											? degreeOptions.find(
													(option) =>
														option.value ===
														values.highestDegree
											  )
											: degreeOptions.find(
													(option) =>
														option.value ===
														values.highestDegree
											  )
									}
									onBlur={handleBlur}
									onChange={(selectedOption) =>
										setFieldValue(
											"highestDegree",
											selectedOption.value
										)
									}
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.highestDegree &&
										touched.highestDegree
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.highestDegree &&
									touched.highestDegree && (
										<Error msg={errors.highestDegree} />
									)}
							</div>

							{/* Field of Study / Major */}
							<div>
								<label
									htmlFor="fieldOfStudy"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Field of Study / Major
								</label>
								<Select
									id="fieldOfStudy"
									name="fieldOfStudy"
									placeholder="Select your major"
									options={fieldOfStudyOptions}
									value={
										values.fieldOfStudy === ""
											? fieldOfStudyOptions.find(
													(option) =>
														option.value ===
														values.fieldOfStudy
											  )
											: fieldOfStudyOptions.find(
													(option) =>
														option.value ===
														values.fieldOfStudy
											  )
									}
									onBlur={handleBlur}
									onChange={(selectedOption) =>
										setFieldValue(
											"fieldOfStudy",
											selectedOption.value
										)
									}
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.fieldOfStudy &&
										touched.fieldOfStudy
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.fieldOfStudy &&
									touched.fieldOfStudy && (
										<Error msg={errors.fieldOfStudy} />
									)}
							</div>

							{/* University */}
							<div className="sm:col-span-2">
								<label
									htmlFor="university"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									University
								</label>
								<Select
									id="university"
									name="university"
									placeholder="Select your university"
									options={universitiesOptions}
									value={
										values.university === ""
											? universitiesOptions.find(
													(option) =>
														option.value ===
														values.university
											  )
											: universitiesOptions.find(
													(option) =>
														option.value ===
														values.university
											  )
									}
									onBlur={handleBlur}
									onChange={(selectedOption) =>
										setFieldValue(
											"university",
											selectedOption.value
										)
									}
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.university && touched.university
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.university && touched.university && (
									<Error msg={errors.university} />
								)}
							</div>

							{/* Institution */}
							<div>
								<label
									htmlFor="institution"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Institution
								</label>
								<Select
									id="institution"
									name="institution"
									placeholder="Select your institution"
									options={institutionsOptions}
									value={
										values.institution === ""
											? institutionsOptions.find(
													(option) =>
														option.value ===
														values.institution
											  )
											: institutionsOptions.find(
													(option) =>
														option.value ===
														values.institution
											  )
									}
									onBlur={handleBlur}
									onChange={(selectedOption) =>
										setFieldValue(
											"institution",
											selectedOption.value
										)
									}
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.institution &&
										touched.institution
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.institution && touched.institution && (
									<Error msg={errors.institution} />
								)}
							</div>

							{/* Graduation Year */}
							<div>
								<label
									htmlFor="graduationYear"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Year of Graduation
								</label>
								<Select
									id="graduationYear"
									name="graduationYear"
									placeholder="Select your graduation year"
									options={graduationYearOptions}
									value={
										values.graduationYear === ""
											? graduationYearOptions.find(
													(option) =>
														option.value ===
														values.graduationYear
											  )
											: graduationYearOptions.find(
													(option) =>
														option.value ===
														values.graduationYear
											  )
									}
									onBlur={handleBlur}
									onChange={(selectedOption) =>
										setFieldValue(
											"graduationYear",
											selectedOption.value
										)
									}
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.graduationYear &&
										touched.graduationYear
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.graduationYear &&
									touched.graduationYear && (
										<Error msg={errors.graduationYear} />
									)}
							</div>

							{/* Graduation Month */}
							<div>
								<label
									htmlFor="graduationMonth"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Month of Graduation
								</label>
								<Select
									id="graduationMonth"
									name="graduationMonth"
									placeholder="Select your graduation month"
									options={graduationMonthOptions}
									value={
										values.graduationMonth === ""
											? graduationMonthOptions.find(
													(option) =>
														option.value ===
														values.graduationMonth
											  )
											: graduationMonthOptions.find(
													(option) =>
														option.value ===
														values.graduationMonth
											  )
									}
									onBlur={handleBlur}
									onChange={(selectedOption) =>
										setFieldValue(
											"graduationMonth",
											selectedOption.value
										)
									}
									className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
										errors.graduationMonth &&
										touched.graduationMonth
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
								/>
								{errors.graduationMonth &&
									touched.graduationMonth && (
										<Error msg={errors.graduationMonth} />
									)}
							</div>

							{/* CGPA/Percentage */}
							<div>
								<label
									htmlFor="cgpaPercentage"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									CGPA / Percentage
								</label>
								<input
									id="cgpaPercentage"
									name="cgpaPercentage"
									type="number"
									value={values.cgpaPercentage}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Enter CGPA/Percentage (e.g., 8.1/85)"
									className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
										errors.cgpaPercentage &&
										touched.cgpaPercentage
											? "border-red-500 focus:ring-red-500"
											: "border-gray-300 focus:ring-blue-500"
									}`}
									step="0.01" // Allows decimal input
									min="0" // Prevents negative values
								/>
								{errors.cgpaPercentage &&
									touched.cgpaPercentage && (
										<Error msg={errors.cgpaPercentage} />
									)}
							</div>

							{/* Skills */}
							<div className="sm:col-span-2">
								<label
									htmlFor="skills"
									className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
								>
									Skills
								</label>
								<div className="relative">
									<input
										id="skills"
										type="text"
										value={currentSkill}
										onChange={(e) =>
											setCurrentSkill(e.target.value)
										}
										onBlur={handleBlur}
										onKeyDown={(e) =>
											e.key === "Enter" &&
											handleAddSkill()
										} // Add skill on Enter key
										placeholder="Enter a skill"
										className="w-full py-2 px-4 pr-16 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
									/>
									<button
										type="button"
										onClick={handleAddSkill}
										className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 focus:outline-none"
									>
										Add
									</button>
								</div>
								{errors.skills && touched.skills && (
									<Error msg={errors.skills} />
								)}
								<div className="flex flex-wrap gap-2 mt-3">
									{skills.map((skill, index) => (
										<div
											key={index}
											className="flex items-center bg-gray-200 text-gray-800 px-3 py-1 rounded-full"
										>
											<span>{skill}</span>
											<button
												type="button"
												onClick={() =>
													handleRemoveSkill(skill)
												}
												className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
											>
												&times;
											</button>
										</div>
									))}
								</div>
							</div>

							{/* Certifications */}
							<div className="sm:col-span-2">
								<label
									htmlFor="certifications"
									className="block text-sm font-medium text-gray-700 mb-2"
								>
									Certifications (if any)
								</label>
								<div className="flex flex-col gap-2">
									<input
										id="certifications"
										name="certifications"
										type="text"
										value={values.certifications}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="e.g., Certified Data Analyst, PMP"
										className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
											errors.certifications &&
											touched.certifications
												? "border-red-500 focus:ring-red-500"
												: "border-gray-300 focus:ring-blue-500"
										}`}
									/>
									{errors.certifications &&
										touched.certifications && (
											<Error
												msg={errors.certifications}
											/>
										)}
								</div>
							</div>

							{/* Certification File */}
							<div className="sm:col-span-2">
								<label className="block text-sm font-medium text-gray-700 mb-2">
									Upload Certification File
								</label>
								<div className="flex items-center justify-center w-full relative">
									<label
										htmlFor="certificationFile"
										className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
									>
										{values.certificationFile ? (
											<div className="w-full h-full flex items-center justify-center overflow-hidden rounded-lg relative">
												{/* Show progress if in progress */}
												{uploadProgress > 0 &&
													uploadProgress < 100 && (
														<div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-800 bg-opacity-50">
															<div className="w-3/4 bg-gray-200 rounded-full h-2">
																<div
																	className="bg-blue-500 h-2 rounded-full"
																	style={`{
													width: ${uploadProgress}%,
												}`}
																/>
															</div>
															<p className="mt-2 text-xs text-white">
																{uploadProgress}
																% Uploading...
															</p>
														</div>
													)}
												{/* Check if the uploaded file is an image */}
												{[
													"image/jpeg",
													"image/png",
												].includes(
													values.certificationFile
														.type
												) ? (
													<img
														src={URL.createObjectURL(
															values.certificationFile
														)}
														alt="Uploaded Preview"
														className="w-full h-full object-cover"
													/>
												) : values.certificationFile
														.type ===
												  "application/pdf" ? (
													<div className="flex flex-col items-center text-gray-700">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															className="w-10 h-10 mb-2 text-red-500"
															fill="none"
															viewBox="0 0 24 24"
															stroke="currentColor"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth={2}
																d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6z"
															/>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth={2}
																d="M14 2v6h6"
															/>
														</svg>
														<p className="text-sm font-medium">
															PDF Uploaded
														</p>
													</div>
												) : (
													<p className="text-sm text-gray-700 font-medium">
														{
															values
																.certificationFile
																.name
														}
													</p>
												)}
											</div>
										) : (
											<div className="flex flex-col items-center">
												<p className="text-sm text-gray-500">
													<span className="font-medium">
														Click to upload
													</span>{" "}
													or drag and drop
												</p>
												<p className="text-xs text-gray-500">
													PDF, PNG, JPG (max 5MB)
												</p>
											</div>
										)}
										<input
											id="certificationFile"
											type="file"
											name="certificationFile"
											accept=".pdf, .jpg, .png"
											onChange={(event) => {
												const file =
													event.currentTarget
														.files[0];
												if (file) {
													setFieldValue(
														"certificationFile",
														file
													);
													handleUpload(file); // Trigger upload
												}
											}}
											className="hidden"
										/>
									</label>
								</div>
								{/* Type and Size */}
								{values.certificationFile && (
									<div className="mt-2 flex justify-between">
										<p className="text-xs text-gray-500">
											Type:{" "}
											{values.certificationFile.type}
										</p>
										<p className="text-xs text-gray-500">
											Size:{" "}
											{(
												values.certificationFile.size /
												1024 /
												1024
											).toFixed(2)}{" "}
											MB
										</p>
									</div>
								)}
								{/* Validation Error */}
								{errors.certificationFile &&
									touched.certificationFile && (
										<Error msg={errors.certificationFile} />
									)}
							</div>
						</div>
						<div className="flex justify-between mt-6">
							<button
								type="button"
								onClick={onBack}
								className="px-4 py-2 border rounded-md bg-gray-100 disabled:opacity-50"
							>
								Previous
							</button>
							<button
								type="submit"
								className="px-4 py-2 border rounded-md bg-blue-600 text-white hover:bg-blue-700"
							>
								Next
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
}
