import React from "react";
import { useLocation, Link } from "react-router-dom";
import { projects } from "../utils/projects";
import Card from "../components/Card";
import { HERO_BG_IMG2, TESTIMONIALS_BG_IMG } from "../utils/constant";

function Projects() {
	const location = useLocation();
	const { pathname } = location;

	// Set document title for the "/projects" route
	if (pathname === "/projects") {
		document.title = "Projects - Sumcon Infraventures";
	}

	// Show only 6 projects on the homepage (`/`) and all projects on `/projects`
	const displayedProjects =
		pathname === "/projects" ? projects : projects.slice(0, 8);

	const backgroundStyle = {
		backgroundImage: `url(${TESTIMONIALS_BG_IMG})`,
		backgroundRepeat: "repeat",
		backgroundPosition: "center center",
	};

	return (
		<>
			<section
				className={`relative h-96 flex p-5 md:p-10 ${
					pathname === "/projects" ? "block" : "hidden"
				}`}
			>
				<div className="absolute inset-0 w-full h-full">
					<img
						src={HERO_BG_IMG2}
						alt=""
						className="w-full h-full bg-cover bg-center object-cover bg-no-repeat"
					/>
					<div className="absolute inset-0 bg-black opacity-40"></div>
				</div>
				<div className="w-full h-full z-10 flex flex-col justify-center items-center px-3 sm:px-5">
					<div className="w-full flex flex-col items-center gap-3 lg:gap-6">
						<h1 className="text-3xl font-medium tracking-wider text-[#4682B4] md:text-4xl lg:text-6xl">
							Our Onsite Projects
						</h1>
						<p className="text-base text-[#ffffff] tracking-wide text-center">
							Delivering Excellence Across India
						</p>
					</div>
				</div>
			</section>
			<section
				className="w-full flex justify-center py-3 px-6 bg-[#708090]/10"
				style={backgroundStyle}
			>
				<div className="container">
					<div
						className={`w-full ${
							pathname === "/projects" ? "hidden" : "flex"
						} flex-col items-center gap-3 lg:gap-6`}
					>
						<h1 className="text-3xl font-medium tracking-wider text-[#4682B4] md:text-4xl lg:text-6xl">
							Our Onsite Projects
						</h1>
						<p className="text-base text-[#333333] tracking-wide text-center">
							Delivering Excellence Across India
						</p>
					</div>
					<div className="w-full md:px-8 grid place-items-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 my-8">
						{displayedProjects.map((item, index) => (
							<Card data={item} key={index} />
						))}
					</div>
					<div className="w-full flex justify-center">
						{pathname !== "/projects" && (
							<Link
								to="/projects"
								className="flex items-center gap-1 px-5 py-2 bg-[#4682B4]/90 text-white rounded-md text-lg font-medium hover:bg-[#4682B4] hover:scale-105 duration-300"
							>
								See more
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="#ffffff"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
									className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"
								>
									<path
										stroke="none"
										d="M0 0h24v24H0z"
										fill="none"
									/>
									<path d="M5 12l14 0" />
									<path d="M13 18l6 -6" />
									<path d="M13 6l6 6" />
								</svg>
							</Link>
						)}
					</div>
				</div>
			</section>
		</>
	);
}

export default Projects;
