export default function Review({ formData, onBack }) {
	console.log(formData);
	const personalDetails = formData.personalDetails;
	const education = formData.education;
	// const experience = formData.experience;

	console.log(education);

	return (
		<div className="">
			<h3 className="text-2xl font-semibold text-gray-800 mb-6 border-b pb-3">
				Review Your Application
			</h3>
			<div className="space-y-8">
				{/* Personal Details */}
				<div className="border p-4 rounded-lg bg-gray-50">
					<h4 className="text-lg font-medium text-gray-700 mb-3">
						Personal Details
					</h4>
					<div className="space-y-2">
						<p>
							<span className="font-semibold">First Name:</span>{" "}
							{personalDetails.firstName}
						</p>
						<p>
							<span className="font-semibold">Last Name:</span>{" "}
							{personalDetails.lastName}
						</p>
						<p>
							<span className="font-semibold">Email:</span>{" "}
							{personalDetails.email}
						</p>
						<p>
							<span className="font-semibold">Phone:</span>{" "}
							{personalDetails.phone}
						</p>
						<p>
							<span className="font-semibold">
								Current Address:
							</span>{" "}
							{personalDetails.currentAddress}
						</p>
						<p>
							<span className="font-semibold">
								Permanent Address:
							</span>{" "}
							{personalDetails.permanentAddress}
						</p>
						<p>
							<span className="font-semibold">Country:</span>{" "}
							{personalDetails.country}
						</p>
						<p>
							<span className="font-semibold">State:</span>{" "}
							{personalDetails.state}
						</p>
						<p>
							<span className="font-semibold">District:</span>{" "}
							{personalDetails.district}
						</p>
						<p>
							<span className="font-semibold">PIN Code:</span>{" "}
							{personalDetails.pinCode}
						</p>
					</div>
				</div>

				{/* Education */}
				<div className="border p-4 rounded-lg bg-gray-50">
					<h4 className="text-lg font-medium text-gray-700 mb-3">
						Education
					</h4>
					<div className="space-y-2">
						<p>
							<span className="font-semibold">
								Highest Degree:
							</span>{" "}
							{formData.highestDegree}
						</p>
						<p>
							<span className="font-semibold">
								Field of Study / Major:
							</span>{" "}
							{formData.fieldOfStudy}
						</p>
						<p>
							<span className="font-semibold">University:</span>{" "}
							{formData.university}
						</p>
						<p>
							<span className="font-semibold">Institution:</span>{" "}
							{formData.institution}
						</p>
						<p>
							<span className="font-semibold">
								Graduation Year:
							</span>{" "}
							{formData.graduationYear}
						</p>
						<p>
							<span className="font-semibold">
								Graduation Month:
							</span>{" "}
							{formData.graduationYear}
						</p>
						<p>
							<span className="font-semibold">
								CGPA / Percentage:
							</span>{" "}
							{formData.cgpaPercentage}
						</p>
						<p>
							<span className="font-semibold">Skills:</span>{" "}
							<ul>
								{formData.skills?.map((skill, index) => (
									<li key={index}>{skill}</li>
								))}
							</ul>
						</p>
						<p>
							<span className="font-semibold">
								Certifications:
							</span>{" "}
							{formData.certifications}
						</p>
						<p>
							<span className="font-semibold">
								Certifications:
							</span>{" "}
							{formData.certifications}
						</p>
					</div>
				</div>

				{/* Experience */}
				<div className="border p-4 rounded-lg bg-gray-50">
					<h4 className="text-lg font-medium text-gray-700 mb-3">
						Experience
					</h4>
					<div className="space-y-2">
						<p>
							<span className="font-semibold">
								Experience Level:
							</span>{" "}
							{formData.experienceLevel}
						</p>
						{formData.experienceLevel === "experienced" && (
							<>
								<p>
									<span className="font-semibold">
										Current Company:
									</span>{" "}
									{formData.currentCompany}
								</p>
								<p>
									<span className="font-semibold">
										Job Title:
									</span>{" "}
									{formData.jobTitle}
								</p>
								<p>
									<span className="font-semibold">
										Years of Experience:
									</span>{" "}
									{formData.yearsOfExperience}
								</p>
								<p>
									<span className="font-semibold">
										Reason for Leaving:
									</span>{" "}
									{formData.reasonForLeaving}
								</p>
								<p>
									<span className="font-semibold">
										Current Salary:
									</span>{" "}
									{formData.currentSalary}
								</p>
								<p>
									<span className="font-semibold">
										Expected Salary:
									</span>{" "}
									{formData.expectedSalary}
								</p>
								<p>
									<span className="font-semibold">
										Notice Period:
									</span>{" "}
									{formData.noticePeriod}
								</p>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="flex justify-between mt-6">
				<button
					type="button"
					onClick={onBack}
					className="px-4 py-2 border rounded-md bg-gray-100 disabled:opacity-50"
				>
					Previous
				</button>
				<button
					type="submit"
					className="px-4 py-2 border rounded-md bg-green-600 text-white hover:bg-green-700"
				>
					Apply
				</button>
			</div>
		</div>
	);
}
