import { useState } from "react";
import { Formik, Form } from "formik";
import Select from "react-select";
import validationSchemas from "../schema/applicationValidationSchema";
import { experienceOptions } from "../utils/education";
import Error from "./Error";

const InputField = ({
	name,
	label,
	type = "text",
	placeholder,
	values,
	errors,
	touched,
	handleChange,
	handleBlur,
	id,
}) => {
	return (
		<div>
			<label
				htmlFor={id}
				className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
			>
				{label}
			</label>
			<input
				id={id}
				name={name}
				type={type}
				value={values[name]}
				onChange={handleChange}
				onBlur={handleBlur}
				placeholder={placeholder}
				className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
					errors[name] && touched[name]
						? "border-red-500 focus:ring-red-500"
						: "border-gray-300 focus:ring-blue-500"
				}`}
			/>
			{errors[name] && touched[name] && (
				<p className="text-red-500 text-sm mt-1">{errors[name]}</p>
			)}
		</div>
	);
};

export default function ExperienceDetails({
	initialValues,
	onNext,
	onBack,
	step,
}) {
	const [isShow, setIsShow] = useState(false);
	console.log(validationSchemas[step]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchemas[step]}
			onSubmit={(values) => {
				onNext(values);
			}}
		>
			{({
				values,
				errors,
				touched,
				setFieldValue,
				handleBlur,
				handleChange,
			}) => {
				const handleSelectChange = (selectedOption) => {
					setFieldValue("experienceLevel", selectedOption.value);
					if (selectedOption.value === "experienced") {
						setIsShow(true);
					} else {
						setIsShow(false);
					}
				};

				return (
					<Form className="space-y-4">
						{/* Experience Level Select */}
						<div>
							<label
								htmlFor="experienceLevel"
								className="block text-sm font-medium text-gray-700 mb-2 after:content-['*'] after:ml-0.5 after:text-red-500"
							>
								Experience Level
							</label>
							<Select
								id="experienceLevel"
								name="experienceLevel"
								placeholder="Select your experience level"
								options={experienceOptions}
								value={
									values.experienceLevel === ""
										? experienceOptions.find(
												(option) =>
													option.value ===
													values.experienceLevel
										  )
										: experienceOptions.find(
												(option) =>
													option.value ===
													values.experienceLevel
										  )
								}
								onBlur={handleBlur}
								onChange={handleSelectChange}
								className={`w-full border rounded-md focus:outline-none focus:ring-2 ${
									errors.experienceLevel &&
									touched.experienceLevel
										? "border-red-500 focus:ring-red-500"
										: "border-gray-300 focus:ring-blue-500"
								}`}
							/>
							{errors.experienceLevel &&
								touched.experienceLevel && (
									<Error msg={errors.experienceLevel} />
								)}
						</div>
						{isShow && (
							<>
								<InputField
									id="currentCompany"
									name="currentCompany"
									label="Current/Most Recent Company"
									type="text"
									errors={errors}
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched}
								/>
								<InputField
									id="jobTitle"
									name="jobTitle"
									label="Job Title"
									type="text"
									errors={errors}
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched}
								/>
								<InputField
									id="yearsOfExperience"
									name="yearsOfExperience"
									label="Years of Experience"
									type="number"
									errors={errors}
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched}
								/>
								<InputField
									id="reasonForLeaving"
									name="reasonForLeaving"
									label="Reason for Leaving"
									type="text"
									errors={errors}
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched}
								/>
								<InputField
									id="currentSalary"
									name="currentSalary"
									label="Current Salary"
									type="number"
									errors={errors}
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched}
								/>
								<InputField
									id="expectedSalary"
									name="expectedSalary"
									label="Expected Salary"
									type="number"
									errors={errors}
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched}
								/>
								<InputField
									id="noticePeriod"
									name="noticePeriod"
									label="Notice Period"
									type="number"
									errors={errors}
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched}
								/>
							</>
						)}

						<div className="flex justify-between mt-6">
							<button
								type="button"
								onClick={onBack}
								className="px-4 py-2 border rounded-md bg-gray-100 disabled:opacity-50"
							>
								Previous
							</button>
							<button
								type="submit"
								className="px-4 py-2 border rounded-md bg-blue-600 text-white hover:bg-blue-700"
							>
								Next
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
}
