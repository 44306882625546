export const jobs = [
	{
		id: 1,
		title: "Senior Frontend Developer",
		department: "Engineering",
		location: "Remote",
		type: "Full-time",
		postedDate: "2023-11-15",
		experience: "5+ years",
		description:
			"We are looking for a Senior Frontend Developer with expertise in modern JavaScript frameworks (React, Vue, or Angular). You'll work closely with designers and backend developers to create responsive and user-friendly interfaces. Strong problem-solving skills and attention to detail are essential.",
	},
	{
		id: 2,
		title: "UX Designer",
		department: "Design",
		location: "New York, NY",
		type: "Full-time",
		postedDate: "2023-11-10",
		experience: "3-5 years",
		description:
			"Join our design team as a UX Designer, where you'll craft intuitive and visually appealing user experiences. You'll conduct user research, create wireframes and prototypes, and collaborate with product managers and engineers to bring designs to life.",
	},
	{
		id: 3,
		title: "DevOps Engineer",
		department: "Operations",
		location: "San Francisco, CA",
		type: "Full-time",
		postedDate: "2023-11-05",
		experience: "4+ years",
		description:
			"We are hiring a DevOps Engineer to optimize our CI/CD pipelines, manage cloud infrastructure, and ensure system reliability. A strong background in AWS, Docker, Kubernetes, and scripting is required.",
	},
	{
		id: 4,
		title: "Content Writer",
		department: "Marketing",
		location: "Remote",
		type: "Part-time",
		postedDate: "2023-11-01",
		experience: "2-3 years",
		description:
			"We are seeking a creative Content Writer to develop compelling content for blogs, social media, and marketing campaigns. If you have a knack for storytelling and a keen eye for detail, we want to hear from you.",
	},
	{
		id: 5,
		title: "Data Analyst",
		department: "Analytics",
		location: "Chicago, IL",
		type: "Contract",
		postedDate: "2023-10-28",
		experience: "1-3 years",
		description:
			"Join our analytics team as a Data Analyst to provide insights through data visualization and reporting. Proficiency in SQL, Python, and tools like Tableau or Power BI is essential.",
	},
];
