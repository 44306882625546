import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./routes/Home";
import AboutUs from "./routes/AboutUs";
import ContactUs from "./routes/ContactUs";
import Footer from "./components/Footer";
import Projects from "./routes/Projects";
import Careers from "./routes/Careers";
import OpenPositions from "./routes/OpenPositions";
import ApplyPage from "./routes/ApplyPage";

function App() {
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			const offset = 100;
			setShowButton(scrollPosition > offset);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};

	return (
		<Router>
			<Navbar />
			<div className="pt-20">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/careers" element={<Careers />} />
					<Route
						path="/careers/open-positions"
						element={<OpenPositions />}
					/>
					<Route
						path="/careers/open-positions/apply/:id"
						element={<ApplyPage />}
					/>
				</Routes>
			</div>
			<Footer />
			<div
				className={`fixed flex bottom-4 z-50 right-4 w-10 h-10 rounded-full bg-[#4682B4] text-white items-center justify-center cursor-pointer ${
					showButton ? "visible" : "invisible"
				}`}
				onClick={scrollToTop}
			>
				<i className="bx bx-up-arrow-alt text-2xl"></i>
			</div>
		</Router>
	);
}

export default App;
