import React from "react";
import { leaders } from "../utils/leaders";
import ProfileCard from "./ProfileCard";

const OurLeadership = () => {
	return (
		<section className="w-full flex justify-center py-12 px-6 bg-[#708090]/10">
			<div className="container">
				<div className="w-full flex flex-col items-center gap-3 lg:gap-6">
					<h1 className="text-2xl font-medium tracking-wider text-[#4682B4] md:text-4xl lg:text-5xl">
						Our Leadership
					</h1>
				</div>
				<div className="w-full md:px-8 flex justify-center flex-wrap gap-3 my-16">
					{leaders.map((item, index) => (
						<ProfileCard data={item} key={index} />
					))}
				</div>
			</div>
		</section>
	);
};

export default OurLeadership;
