import React from "react";

const ProfileCard = (data) => {
	return (
		<div className="w-full max-w-sm h-[400px] flex flex-col justify-center items-center rounded-lg shadow-md bg-white overflow-hidden">
			{/* Wrapper for the image and overlay */}
			<div className="w-full h-96 relative group overflow-hidden cursor-pointer">
				{/* Image */}
				<img
					src={data.data.img}
					alt={`${data.data.name}`}
					className="w-full h-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-125"
				/>
				{/* Overlay */}
				<div className="absolute inset-0 bg-black opacity-20"></div>
			</div>
			{/* Text */}
			<div className="flex flex-col justify-center items-center py-2">
				<span className="font-serif font-semibold text-2xl capitalize text-[#4682B4]">
					{data.data.name}
				</span>
				<span className="font-serif text-center font-semibold text-base capitalize text-[#333333]">
					{data.data.role}
				</span>
			</div>
		</div>
	);
};

export default ProfileCard;
