import { useState } from "react";
import PersonalDetails from "./PersonalDetails";
import EducationDetails from "./Education";
import ExperienceDetails from "./Experience";
import Review from "./Review";

const initialValues = [
	{
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		currentAddress: "",
		permanentAddress: "",
		sameAsCurrentAddress: false,
		state: "",
		district: "",
		pinCode: "",
		country: "",
	},
	{
		highestDegree: "",
		fieldOfStudy: "",
		institution: "",
		university: "",
		graduationYear: "",
		graduationMonth: "",
		cgpaPercentage: "",
		skills: [],
		certifications: "",
		certificationFile: null,
	},
	{
		experienceLevel: "",
		currentCompany: "",
		jobTitle: "",
		yearsOfExperience: "",
		reasonForLeaving: "",
		currentSalary: "",
		expectedSalary: "",
		noticePeriod: "",
	},
];

export default function ApplicationForm() {
	const steps = ["Personal Details", "Education", "Experience", "Review"];
	const [step, setStep] = useState(0);
	const [formData, setFormData] = useState({});

	// Merge formData with initial values for the current step
	const toCamelCase = (str) => {
		return str
			.split(" ") // Split by spaces
			.map((word, index) => {
				if (index === 0) {
					return word.toLowerCase(); // First word should be lowercase
				}
				// For other words, capitalize the first letter and make the rest lowercase
				return (
					word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
				);
			})
			.join(""); // Join the words back together
	};

	const getCurrentStepValues = (stepIndex) => {
		const stepKey = toCamelCase(steps[stepIndex]);
		return {
			...initialValues[stepIndex],
			...formData[stepKey],
		};
	};

	const handleNext = (data) => {
		const stepKey = toCamelCase(steps[step]);
		// Store the data for the current step
		setFormData((prev) => ({
			...prev,
			[stepKey]: data,
		}));
		setStep((prev) => prev + 1);
	};

	const handleBack = () => {
		setStep((prev) => prev - 1);
	};

	return (
		<div>
			{/* Progress Bar */}
			<div className="relative w-full px-7 my-10">
				<div className="h-2 bg-gray-200 rounded-full">
					<div
						className="h-full bg-blue-600 rounded-full transition-all duration-300"
						style={{
							width: `${((step + 1) / steps.length) * 100}%`,
						}}
					></div>
				</div>

				{/* Step Indicators */}
				<div className="flex justify-between absolute -top-3 left-0 w-full">
					{steps.map((stepLabel, index) => (
						<div
							key={stepLabel}
							className="flex flex-col items-center"
						>
							<div
								className={`w-8 h-8 flex items-center justify-center rounded-full text-white font-bold ${
									index <= step
										? "bg-blue-600"
										: "bg-gray-300"
								}`}
							>
								{index + 1}
							</div>
							<span className="text-xs mt-1 hidden md:block">
								{stepLabel}
							</span>
						</div>
					))}
				</div>
			</div>

			{/* Form Content */}
			<div className="md:mt-16">
				{step === 0 && (
					<PersonalDetails
						initialValues={getCurrentStepValues(0)}
						onNext={handleNext}
						step={step}
					/>
				)}
				{step === 1 && (
					<EducationDetails
						initialValues={getCurrentStepValues(1)}
						onNext={handleNext}
						onBack={handleBack}
						step={step}
					/>
				)}
				{step === 2 && (
					<ExperienceDetails
						initialValues={getCurrentStepValues(2)}
						onNext={handleNext}
						onBack={handleBack}
						step={step}
					/>
				)}
				{step === 3 && (
					<Review formData={formData} onBack={handleBack} />
				)}
			</div>
		</div>
	);
}
