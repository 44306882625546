export const COMPANY_NAME = "sumcon infraventures";
export const LOGO1 = require("../assets/logo-1.png");
export const HERO_BG_IMG = require("../assets/transport-concept-with-railroad-aerial-view.jpg");
export const HERO_BG_IMG1 = require("../assets/beautiful-shot-train-passing-bridge-sunny-day.jpg");
export const HERO_BG_IMG2 = require("../assets/shipping-worker-looking-train-coming-station-organizing-goods-distribution-export.jpg");
export const ABOUT_BG = require("../assets/5138438-removebg-preview.png");
export const TESTIMONIALS_BG_IMG = require("../assets/design-img.png");
export const VISION_IMG = require("../assets/train-railway.jpg");
export const MISSION_IMG = require("../assets/Mission Image.jpg");
export const TEAMS_IMG = require("../assets/teams/photo-1556761175-5973dc0f32e7.avif");
