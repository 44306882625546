import React from "react";
import InfiniteMovingCards from "./InfiniteMovingCards";
import ClientCard from "./ClientCard";
import { clients } from "../utils/clients";

const OurClient = () => {
	return (
		<div className="w-full py-7 bg-gray-300">
			<InfiniteMovingCards Card={ClientCard} data={clients} />
		</div>
	);
};

export default OurClient;
