import React from "react";
import { Link } from "react-router-dom";
import { LOGO1, COMPANY_NAME } from "../utils/constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import Error from "./Error";

function Footer() {
	const { values, handleBlur, handleChange, errors, handleSubmit, touched } =
		useFormik({
			initialValues: {
				email: "",
			},
			validationSchema: Yup.object({
				email: Yup.string()
					.email("Invalid email address")
					.required("Email is required"),
			}),
			onSubmit: (values, { resetForm }) => {
				alert(`Subscribed successfully: ${values.email}`);
				resetForm();
			},
		});

	return (
		<footer className="w-full flex justify-center py-10 px-5 bg-[#333333]">
			<div className="container max-w-7xl">
				{/* Main Content */}
				<div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-8">
					{/* Company Info */}
					<div className="max-w-64 md:w-fit col-span-1 lg:col-span-2">
						<div className="w-20 h-20 rounded-full mb-3">
							<img
								src={LOGO1}
								alt="logo"
								className="w-full h-full rounded-full"
							/>
						</div>
						<h2 className="text-xl text-white uppercase font-bold tracking-widest mb-2">
							{COMPANY_NAME}
						</h2>
						<span className="text-base tracking-wider text-neutral-100 opacity-75">
							Sumcon Infraventures Private Limited: Pioneering
							railway infrastructure with excellence and
							reliability.
						</span>
						<div className="mt-5 flex gap-3 items-center">
							<Link to="/">
								<i className="bx bxl-instagram text-3xl text-white hover:text-purple-600 duration-200"></i>
							</Link>
							<Link
								to="https://www.facebook.com/profile.php?id=100069338070435&mibextid=ZbWKwL"
								target="_blank"
							>
								<i className="bx bxl-facebook-circle text-3xl text-white hover:text-blue-600 duration-200"></i>
							</Link>
							<Link to="/">
								<i className="bx bxl-twitter text-3xl text-white hover:text-sky-600 duration-200"></i>
							</Link>
							<Link
								to="https://www.linkedin.com/company/sumcon-infraventure-llp/"
								target="_blank"
							>
								<i className="bx bxl-linkedin text-3xl text-white hover:text-blue-500 duration-200"></i>
							</Link>
						</div>
					</div>

					{/* Quick Links */}
					<div className="w-fit">
						<h1 className="text-2xl text-white font-bold tracking-wider mb-3">
							Quick Links
						</h1>
						<div className="w-full flex flex-col gap-2">
							<Link
								to="/"
								className="w-fit text-base tracking-normal text-neutral-100 opacity-75 hover:scale-125 duration-300"
							>
								Home
							</Link>
							<Link
								to="/about-us"
								className="w-fit text-base tracking-normal text-neutral-100 opacity-75 hover:scale-125 duration-300"
							>
								About Us
							</Link>
							<Link
								to="/projects"
								className="w-fit text-base tracking-normal text-neutral-100 opacity-75 hover:scale-125 duration-300"
							>
								Projects
							</Link>
							<Link
								to="/contact-us"
								className="w-fit text-base tracking-normal text-neutral-100 opacity-75 hover:scale-125 duration-300"
							>
								Contact Us
							</Link>
							<Link
								to="/careers"
								className="w-fit text-base tracking-normal text-neutral-100 opacity-75 hover:scale-125 duration-300"
							>
								Careers
							</Link>
						</div>
					</div>

					{/* Contact Us */}
					<div className="w-fit col-span-2">
						<h1 className="text-2xl text-white font-bold tracking-wider mb-3">
							Contact Us
						</h1>
						<div className="w-full flex flex-col gap-2">
							<Link
								to="tel:00340047620"
								className="w-fit flex flex-row gap-3 justify-start items-center text-base tracking-normal text-neutral-100 opacity-75"
							>
								<i className="bx bx-phone-call text-xl text-white"></i>
								<span>(033) 4004-7620</span>
							</Link>
							<Link
								to="https://www.google.com/maps/place/SUMCON+INFRAVENTURES+LLP/@22.5774401,88.3499099,15z/data=!4m6!3m5!1s0x3a0277bb1711b4f3:0x163c80b8d5a167f0!8m2!3d22.5774401!4d88.3499099!16s%2Fg%2F11fn7j77nz?entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D"
								target="_blank"
								className="w-fit flex flex-row gap-3 justify-start items-center text-base tracking-normal text-neutral-100 opacity-75"
							>
								<i className="bx bx-map text-xl text-white"></i>
								<span>
									3rd Floor, 46, B.B Ganguly Street, Kolkata,
									700012
								</span>
							</Link>
							<span
								onClick={() => {
									window.location =
										"mailto:info@sumconinfraventures.com";
								}}
								className="w-fit flex flex-row gap-3 justify-start items-center text-base tracking-normal text-neutral-100 opacity-75 cursor-pointer"
							>
								<i className="bx bx-envelope text-xl text-white"></i>
								<span>info@sumconinfraventures.com</span>
							</span>

							{/* Subscription Form */}
							<form
								onSubmit={handleSubmit}
								className="flex flex-col items-start sm:flex-row gap-3 sm:items-start mt-3"
							>
								<div>
									<input
										type="email"
										name="email"
										placeholder="Enter your email"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
										className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
											errors.email && touched.email
												? "border-red-500 focus:ring-red-500"
												: "border-gray-300 focus:ring-blue-500"
										}`}
									/>
									{errors.email && touched.email && (
										<Error msg={errors.email} />
									)}
								</div>
								<button
									type="submit"
									className="px-5 py-2 bg-[#4682B4] text-white rounded-md hover:bg-[#4682B4]/90 transition duration-300"
								>
									Subscribe
								</button>
							</form>
						</div>
					</div>
				</div>

				{/* Footer Bottom */}
				<hr className="w-full h-[1px] bg-white my-5 lg:my-10" />
				<div className="flex flex-col gap-2 sm:flex-row sm:justify-between">
					<span className="text-white/60 text-sm">
						<Link to="https://www.sumconinfraventures.com/">
							Sumcon Infraventures.
						</Link>{" "}
						&#169;copyright 2023
					</span>
					<div className="flex flex-row gap-5 text-white/60 text-sm">
						<Link to="/" className="cursor-pointer">
							Privacy Policy
						</Link>
						<Link to="/" className="cursor-pointer">
							Terms & Conditions
						</Link>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
