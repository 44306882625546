import React from "react";
import { HERO_BG_IMG, HERO_BG_IMG1, HERO_BG_IMG2 } from "../utils/constant";
import { Link } from "react-router-dom";
import Carousel from "nuka-carousel";

function Hero() {
	const bg_img = [HERO_BG_IMG, HERO_BG_IMG1, HERO_BG_IMG2];
	return (
		<section className="relative min-h-screen flex p-5 md:p-10">
			<div className="absolute inset-0 w-full h-full">
				<Carousel
					autoplay
					autoplayInterval={3000}
					slidesToScroll={1}
					wrapAround={true}
					withoutControls
				>
					{bg_img.map((item, index) => {
						return (
							<img
								src={item}
								key={index}
								alt="hero background"
								className="w-full h-screen bg-cover bg-center object-cover bg-no-repeat"
							/>
						);
					})}
				</Carousel>
				<div className="absolute inset-0 bg-black opacity-40"></div>
			</div>
			<div className="max-w-7xl h-full z-10 flex flex-col justify-center items-center px-3 sm:px-5 mt-32 md:mt-36">
				<div className="w-full text-left">
					<h2 className="text-4xl capitalize font-semibold md:text-4xl lg:text-6xl text-white">
						Leading the Path of Infrastructure Excellence
					</h2>
					<p className="text-left text-lg leading-7 text-[#e1effe] mt-5 tracking-wider max-w-5xl">
						Transforming railway infrastructure with innovative
						solutions, exceptional quality, and a commitment to
						delivering reliable and sustainable projects worldwide.
					</p>
				</div>
				<Link
					to="/contact-us"
					className="w-full self-start mt-10 bg-transparent cursor-pointer "
				>
					<button className="px-10 py-3 rounded-full lg:text-xl lg:font-bold text-white bg-[#FF4500] hover:bg-[#FF4500]/80 duration-200 hover:scale-110 ease-in-out">
						Contact Us
					</button>
				</Link>
			</div>
		</section>
	);
}

export default Hero;
