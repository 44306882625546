import React from "react";
import { ceo_message } from "../utils/ceo_message";

const CEOMessage = () => {
	const name = ceo_message.name;
	const role = ceo_message.role;
	const img = ceo_message.img;
	const message = ceo_message.message;
	return (
		<div className="max-w-4xl mx-auto bg-white rounded-lg p-10">
			<div className="w-full flex flex-col items-center gap-3 lg:gap-6">
				<h3 className="text-4xl font-bold tracking-wider text-[#333333]">
					A Message From Our CEO
				</h3>
			</div>
			<div className="flex flex-col sm:flex-row items-center gap-6 pb-6 border-b border-gray-200 mt-10">
				<div className="relative w-32 h-32 rounded-full overflow-hidden border">
					<img
						src={img}
						alt={`Portrait of ${name}`}
						sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
						className="object-cover"
					/>
				</div>
				<div className="text-center sm:text-left">
					<h2 className="text-2xl font-bold text-[#4682B4]">
						{name}
					</h2>
					<p className="text-gray-500">{role}</p>
				</div>
			</div>
			<div className="mt-6">
				<blockquote className="text-lg italic border-l-4 border-[#4682B4] pl-4 py-2 rounded-t-md rounded-b-md">
					{message}
				</blockquote>
			</div>
		</div>
	);
};

export default CEOMessage;
