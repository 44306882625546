import * as Yup from "yup";

const validationSchemas = [
	Yup.object({
		firstName: Yup.string()
			.required("First name is required")
			.matches(/^[a-zA-Z]+$/, "Only letters are allowed"),
		lastName: Yup.string()
			.required("Last name is required")
			.matches(/^[a-zA-Z]+$/, "Only letters are allowed"),
		email: Yup.string()
			.email("Invalid email")
			.required("Email is required"),
		phone: Yup.string()
			.required("Phone is required")
			.matches(
				/^\+\d{1,3}\d{10,13}$/,
				"Phone must be a valid number with country code"
			),
		currentAddress: Yup.string().required("Current address is required"),
		permanentAddress: Yup.string().test(
			"permanent-address-required",
			"Permanent address is required",
			function (value) {
				const { sameAsCurrentAddress } = this.parent;
				return sameAsCurrentAddress === false ? !!value : true;
			}
		),
		sameAsCurrentAddress: Yup.boolean(),
		country: Yup.string().required("Country is required"),
		state: Yup.string().required("State is required"),
		district: Yup.string().required("District is required"),
		pinCode: Yup.string()
			.matches(/^\d{6}$/, "Invalid PIN code. Must be exactly 6 digits.")
			.required("PIN code is required"),
	}),
	Yup.object({
		highestDegree: Yup.string().required("Degree is required"),
		fieldOfStudy: Yup.string().required(
			"Field of Study / Major is required"
		),
		institution: Yup.string().required("Institution name is required"),
		university: Yup.string().required("University name is required"),
		graduationYear: Yup.number()
			.min(1900, "Invalid year")
			.max(new Date().getFullYear(), "Invalid year")
			.required("Graduation year is required"),
		graduationMonth: Yup.string()
			.oneOf(
				[
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December",
				],
				"Invalid month"
			)
			.required("Graduation month is required"),
		cgpaPercentage: Yup.string()
			.required("CGPA / Percentage is required")
			.matches(
				/^(\d{1,3}(\.\d{1,2})?)%?$/,
				"Please enter a valid CGPA (e.g., 3.8 or 85%)"
			),
		skills: Yup.array()
			.of(
				Yup.string()
					.trim()
					.min(2, "Each skill must be at least 2 characters.")
			)
			.min(1, "At least one skill is required."),
		certifications: Yup.string().nullable(),
		certificationFile: Yup.mixed()
			.nullable()
			.test(
				"fileSize",
				"File size is too large (max 3MB)",
				(value) => !value || value.size <= 3 * 1024 * 1024
			)
			.test(
				"fileType",
				"Unsupported file type (only PDF, JPG, PNG allowed)",
				(value) =>
					!value ||
					["application/pdf", "image/jpeg", "image/png"].includes(
						value.type
					)
			),
	}),
	Yup.object({
		experienceLevel: Yup.string().required("Experience level is required"),
		currentCompany: Yup.string().when("experienceLevel", {
			is: "experienced",
			then: (schema) => schema.required("Current company is required"),
			otherwise: (schema) => schema.notRequired(),
		}),
		jobTitle: Yup.string().when("experienceLevel", {
			is: "experienced",
			then: (schema) => schema.required("Job title is required"),
			otherwise: (schema) => schema.notRequired(),
		}),
		yearsOfExperience: Yup.number().when("experienceLevel", {
			is: "experienced",
			then: (schema) =>
				schema
					.min(0, "Invalid years")
					.max(50, "Invalid years")
					.required("Years of experience is required"),
			otherwise: (schema) => schema.notRequired(),
		}),
		reasonForLeaving: Yup.string().when("experienceLevel", {
			is: "experienced",
			then: (schema) =>
				schema
					.required("Reason for leaving is required")
					.min(5, "Please provide a more detailed reason"),
			otherwise: (schema) => schema.notRequired(),
		}),
		currentSalary: Yup.number().when("experienceLevel", {
			is: "experienced",
			then: (schema) =>
				schema
					.min(0, "Invalid salary")
					.required("Current salary is required"),
			otherwise: (schema) => schema.notRequired(),
		}),
		expectedSalary: Yup.number().when("experienceLevel", {
			is: "experienced",
			then: (schema) =>
				schema
					.min(0, "Invalid salary")
					.required("Expected salary is required"),
			otherwise: (schema) => schema.notRequired(),
		}),
		noticePeriod: Yup.number().when("experienceLevel", {
			is: "experienced",
			then: (schema) =>
				schema
					.min(0, "Notice period must be a valid number of days")
					.required("Notice period is required"),
			otherwise: (schema) => schema.notRequired(),
		}),
	}),
	Yup.object(), // For Review step, leave validation empty or add any needed checks
];

export default validationSchemas;
