import React from "react";
import { useLocation } from "react-router-dom";
import Hero from "../components/Hero";
import AboutUs from "./AboutUs";
import Projects from "./Projects";
import ContactUs from "./ContactUs";
import OurClient from "../components/OurClient";
import CEOMessage from "../components/CEOMessage";

function Home() {
	const location = useLocation();
	const { pathname } = location;

	if (pathname === "/") document.title = "Welcome To Sumcon Infraventures";

	return (
		<>
			<Hero />
			<AboutUs />
			<CEOMessage />
			<Projects />
			<OurClient />
			<ContactUs />
		</>
	);
}

export default Home;
