import React from "react";
import { TEAMS_IMG } from "../utils/constant";
import AboutUs from "./AboutUs";
import CEOMessage from "../components/CEOMessage";
import { Link } from "react-router-dom";

const Careers = () => {
	return (
		<>
			<section className="relative min-h-screen flex items-center justify-center p-5 md:p-10 bg-black">
				{/* Background Image */}
				<div className="absolute inset-0">
					<img
						src={TEAMS_IMG}
						alt="Our Team"
						className="w-full h-full object-cover"
						aria-label="Background image of our team"
					/>
					{/* Overlay */}
					<div className="absolute inset-0 bg-black bg-opacity-50"></div>
				</div>

				{/* Content */}
				<div className="relative z-10 text-center px-5">
					<h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white leading-tight">
						Join Our Team
					</h1>
					<p className="mt-4 text-lg md:text-xl text-white/80 max-w-2xl mx-auto">
						Become a part of our journey and contribute to impactful
						projects that make a difference.
					</p>
					<div className="mt-6">
						<Link
							to="/contact-us"
							className="px-6 py-3 text-lg font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-500 transition duration-300"
						>
							Contact Us
						</Link>
					</div>
				</div>
			</section>
			<AboutUs />
			<CEOMessage />
		</>
	);
};

export default Careers;
