import blt_image1 from "../assets/sites/blt/image1.png";
import blt_image2 from "../assets/sites/blt/image2.png";
import blt_image3 from "../assets/sites/blt/image3.png";
import earth_works_image1 from "../assets/sites/earth_works/image1.png";
import earth_works_image2 from "../assets/sites/earth_works/image2.png";
import jajpur_image1 from "../assets/sites/jajpur/image1.png";
import jajpur_image2 from "../assets/sites/jajpur/image2.png";
import jajpur_image3 from "../assets/sites/jajpur/image3.png";
import jajpur_image4 from "../assets/sites/jajpur/image4.png";
import jsl_image1 from "../assets/sites/jsl/image1.png";
import jsl_image2 from "../assets/sites/jsl/image2.png";
import jsl_joipur_image1 from "../assets/sites/jsl_joipur/image1.png";
import jsl_joipur_image2 from "../assets/sites/jsl_joipur/image2.png";
import jsl_joipur_image3 from "../assets/sites/jsl_joipur/image3.png";
import jsl_joipur_image4 from "../assets/sites/jsl_joipur/image4.png";
import jsl_joipur_image5 from "../assets/sites/jsl_joipur/image5.png";
import jsl_joipur_image6 from "../assets/sites/jsl_joipur/image6.png";
import krur_image1 from "../assets/sites/karur/image1.png";
import krur_image2 from "../assets/sites/karur/image2.png";
import krur_image3 from "../assets/sites/karur/image3.png";
import krur_image4 from "../assets/sites/karur/image4.png";
import krur_image5 from "../assets/sites/karur/image5.png";
import krur_image6 from "../assets/sites/karur/image6.png";
import ohe_image1 from "../assets/sites/ohe/image1.png";
import pradip_image1 from "../assets/sites/pradip/image1.png";
import tamilnadu_image1 from "../assets/sites/tamilnadu/image1.png";
import tamilnadu_image2 from "../assets/sites/tamilnadu/image2.png";
import tamilnadu_image3 from "../assets/sites/tamilnadu/image3.png";

export const projects = [
	{ site_name: "BLT Works, Jammu", image: blt_image1 },
	{ site_name: "BLT Works, Jammu", image: blt_image2 },
	{ site_name: "BLT Works, Jammu", image: blt_image3 },
	{ site_name: "Earth Works, Raipur", image: earth_works_image1 },
	{ site_name: "Earth Works, Madhukunda", image: earth_works_image2 },
	{ site_name: "Jajpur", image: jajpur_image1 },
	{ site_name: "Jajpur", image: jajpur_image2 },
	{ site_name: "Jajpur", image: jajpur_image3 },
	{ site_name: "Jajpur", image: jajpur_image4 },
	{ site_name: "Raipur", image: jsl_image1 },
	{ site_name: "Raipur", image: jsl_image2 },
	{ site_name: "Madhukunda", image: jsl_joipur_image1 },
	{ site_name: "Madhukunda", image: jsl_joipur_image2 },
	{ site_name: "Madhukunda", image: jsl_joipur_image3 },
	{ site_name: "Madhukunda", image: jsl_joipur_image4 },
	{ site_name: "Tadipatri", image: jsl_joipur_image5 },
	{ site_name: "Tadipatri", image: jsl_joipur_image6 },
	{ site_name: "Maihar", image: krur_image1 },
	{ site_name: "Maihar", image: krur_image2 },
	{ site_name: "Maihar", image: krur_image3 },
	{ site_name: "Karur", image: krur_image4 },
	{ site_name: "Karur", image: krur_image5 },
	{ site_name: "Karur", image: krur_image6 },
	{ site_name: "OHE Work, Jogighopax", image: ohe_image1 },
	{ site_name: "Paradip", image: pradip_image1 },
	{ site_name: "Tadipatri", image: tamilnadu_image1 },
	{ site_name: "Tadipatri", image: tamilnadu_image2 },
	{ site_name: "Tadipatri", image: tamilnadu_image3 },
];
