import { useState } from "react";
import { Link } from "react-router-dom";
import { jobs as initialJobs } from "../utils/jobs";

export default function OpenPositions() {
	const [jobs, setJobs] = useState(initialJobs);
	const [filter, setFilter] = useState("");

	const handleFilterChange = (e) => {
		const value = e.target.value.toLowerCase();
		setFilter(value);

		if (value === "") {
			setJobs(initialJobs); // Reset to the initial jobs list
		} else {
			const filteredJobs = initialJobs.filter(
				(job) =>
					job.title.toLowerCase().includes(value) ||
					job.department.toLowerCase().includes(value) ||
					job.location.toLowerCase().includes(value) ||
					job.experience.toLowerCase().includes(value)
			);
			setJobs(filteredJobs);
		}
	};

	return (
		<div className="p-4">
			<h1 className="text-2xl md:text-3xl font-bold text-center mb-6">
				All Positions
			</h1>
			<div className="mb-6">
				<label
					htmlFor="job-search"
					className="block text-sm font-medium text-gray-700 mb-2"
				>
					Search for a position
				</label>
				<input
					id="job-search"
					type="text"
					placeholder="Filter by title, department, location, or experience"
					value={filter}
					onChange={handleFilterChange}
					className="w-full max-w-md p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
				/>
			</div>
			<div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
				{jobs.map((job) => (
					<div
						key={job.id}
						className="border border-gray-300 rounded-lg shadow-md overflow-hidden"
					>
						<div className="p-4 bg-gray-100">
							<h3 className="text-lg font-semibold">
								{job.title}
							</h3>
						</div>
						<div className="p-4">
							<p className="text-sm text-gray-500 mb-2">
								Department: {job.department}
							</p>
							<p className="text-sm text-gray-500 mb-2">
								Location: {job.location}
							</p>
							<p className="text-sm text-gray-500 mb-2">
								Experience: {job.experience}
							</p>
							<span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded">
								{job.type}
							</span>
						</div>
						<div className="p-4 bg-gray-50 flex justify-between items-center">
							<span className="text-sm text-gray-500">
								Posted: {job.postedDate}
							</span>
							<Link
								to={`/careers/open-positions/apply/${job.id}`}
								className="px-4 py-2 border border-gray-300 rounded-md text-sm text-blue-600 hover:bg-blue-50"
							>
								Apply Now
							</Link>
						</div>
					</div>
				))}
			</div>
			{jobs.length === 0 && (
				<p className="text-center mt-6 text-gray-500">
					No positions found matching your criteria.
				</p>
			)}
		</div>
	);
}
